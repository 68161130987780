import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Switch,
  withRouter,
} from 'react-router-dom';

import {
  Dialog,
  Slide
} from '@mui/material';

import LanguageHOC from 'utils/translations/LanguageHOC';

import { 
  LegalFooter,
  SplashScreen
} from '@frontend/common';

import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';

import Header from './Header';
import Sidenav from './Sidenav';
import NotificationsDrawer from './NotificationsDrawer';
import AppTour from './AppTour';

import Login from 'components/Features/public/Login';
import PageNotFound from 'components/Features/public/PageNotFound';
import ForgotPassword from 'components/Features/public/ForgotPassword';
import Register from 'components/Features/public/RegistrationType/Register';
import ResendEmail from 'components/Features/public/ResendEmail';
import ResetPassword from 'components/Features/public/ResetPassword';
import ValidateEmail from 'components/Features/public/ValidateEmail';
import ForgotUsername from 'components/Features/public/ForgotUsername';
import SetupUser from 'components/Features/public/RegistrationType/SetupUser';
import LpoaAccount from 'components/Features/public/LpoaAccount';
import InterestedPartiesActivation from 'components/Features/public/InterestedPartiesActivation';

import Accounts from 'components/Features/protected/Accounts';
import TransactionHub from 'components/Features/protected/TransactionsHub';
import Home from 'components/Features/protected/Home';
import Lpoa from 'components/Features/protected/Lpoa';
import Gifting from 'components/Features/protected/Gifting/Dashboard';
import Manage from 'components/Features/protected/Gifting/Manage';
import MyInfo from 'components/Features/protected/MyInfo';
import Transactions from 'components/Features/protected/Accounts/Transactions';
import Payments from 'components/Features/protected/Payments';
import Documents from 'components/Features/protected/Documents';
import PayrollContribution from 'components/Features/protected/PayrollContribution';
import InterestedParties from 'components/Features/protected/InterestedParties';

// Potentially Blocked Components - see ProtectedRoute
import VerifyEmail from 'components/Features/protected/VerifyEmail';
import Multifactor from 'components/Features/protected/Multifactor';
import Signup from 'components/Features/protected/Signup';
import AddBirthdate from 'components/Features/protected/AddBirthdate';

import PreflightLoader from 'components/Features/protected/Accounts/Transactions/PreflightLoader';
import Authentication from 'components/Features/protected/Multifactor/Authentication';
import AuthyDown from 'components/Features/protected/Multifactor/AuthyDown';
import RSA from 'components/Features/protected/Multifactor/RSA';

import { getTitle } from 'utils/helpers/title_handler';
import ErrorBoundary from 'utils/helpers/error_boundary';
import { clearState } from 'utils/helpers/state_handler';
import { ROUTE_ACCESS } from './RequiredAccess';
import { REASONS_BLOCKED } from './constants';

import ViewInvestmentPath from 'components/Features/protected/Accounts/AccountDetails/ViewInvestmentPath';

import ScrollToTop from './ScrollToTop';

import { 
  userLogout, 
  resetClaims,
  toggle2FADialog,
  toggleAuthyDownDialog,
  toggleRSADialog,
  toggleSplashHasDisplayed,
} from 'components/AppRoot/Navigation/actions';

import styles from './styles.module.scss';

const select = (state) => ({
  isAccountListLoaded: state.accounts.isAccountListLoaded,
  uiPermissions: state.accounts.uiPermissions,
  documents: state.static.documents,
  isValid: state.session.isValid,
  preflightLoaderOpen: state.transactions.preflightLoaderOpen,
  sidenavOpen: state.session.sidenavOpen,
  userAccess: state.session.userAccess,
  is2FADialogOpen: state.session.is2FADialogOpen,
  isRSADialogOpen: state.session.isRSADialogOpen,
  isAuthyDownDialogOpen: state.session.isAuthyDownDialogOpen,
  accountBlocked: state.session.accountBlocked,
  reasonBlocked: state.session.reasonBlocked,
  splashHasDisplayed: state.session.splashHasDisplayed,
  isNewUser_AccessRole: state.session.isNewUser_AccessRole,
});

export class Navigation extends Component {

  static propTypes = {
    isAccountListLoaded: PropTypes.bool.isRequired,
    userLogout: PropTypes.func.isRequired,
    resetClaims: PropTypes.func.isRequired,
    toggle2FADialog: PropTypes.func.isRequired,
    toggleAuthyDownDialog: PropTypes.func.isRequired,
    toggleRSADialog: PropTypes.func.isRequired,
    toggleSplashHasDisplayed: PropTypes.func.isRequired,
    splashHasDisplayed: PropTypes.bool.isRequired,
    documents: PropTypes.object,
    isValid: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }).isRequired,
    preflightLoaderOpen: PropTypes.bool.isRequired,
    sidenavOpen: PropTypes.bool.isRequired,
    is2FADialogOpen: PropTypes.bool.isRequired,
    isAuthyDownDialogOpen: PropTypes.bool.isRequired,
    isRSADialogOpen: PropTypes.bool.isRequired,
    isNewUser_AccessRole: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Accounts: PropTypes.shape({ nav_path: PropTypes.string }),
      TransactionsHub: PropTypes.shape({ nav_path: PropTypes.string }),
      AccountDetails: PropTypes.shape({ nav_path: PropTypes.func, investment_path: PropTypes.string, }),
      AddBirthdate: PropTypes.shape({ nav_path: PropTypes.string }),
      Home: PropTypes.shape({ nav_path: PropTypes.string }),
      ForgotPassword: PropTypes.shape({ nav_path: PropTypes.string }),
      Gifting: PropTypes.shape({ nav_path: PropTypes.string }),
      Login: PropTypes.shape({ nav_path: PropTypes.string, msg_you_have_logged_out: PropTypes.string }),
      MyInfo: PropTypes.shape({ nav_path: PropTypes.string }),
      Payments: PropTypes.shape({ nav_path: PropTypes.string }),
      ResetPassword: PropTypes.shape({ nav_path: PropTypes.string }),
      Signup: PropTypes.shape({ nav_path: PropTypes.string }),
      Transactions: PropTypes.shape({ nav_path: PropTypes.func }),
      ValidateEmail: PropTypes.shape({ nav_path: PropTypes.string }),
      ForgotUsername: PropTypes.shape({ nav_path: PropTypes.string }),
      Documents: PropTypes.shape({ nav_path: PropTypes.string }),
      PayrollContribution: PropTypes.shape({ nav_path: PropTypes.string }),
      Register: PropTypes.shape({ nav_path: PropTypes.string }),
      ResendEmail: PropTypes.shape({ nav_path: PropTypes.string }),
      SetupUser: PropTypes.shape({ nav_path: PropTypes.string }),
      VerifyEmail: PropTypes.shape({ nav_path: PropTypes.string }),
      Multifactor: PropTypes.shape({ nav_path: PropTypes.string }),
      Lpoa: PropTypes.shape({ nav_path: PropTypes.string }),
      InterestedParties: PropTypes.shape({ nav_path: PropTypes.string }),
      LpoaAccount: PropTypes.shape({ nav_path: PropTypes.string }),
      InterestedPartiesActivation: PropTypes.shape({ nav_path: PropTypes.string }),
    }).isRequired,
    userAccess: PropTypes.string.isRequired,
    uiPermissions: PropTypes.shape({
      canAddInterestedParties: PropTypes.bool,
      canManageBankAccounts: PropTypes.bool,
      giftingEnabled: PropTypes.bool,
      noBeneAccounts: PropTypes.bool,
    }).isRequired,
    accountBlocked: PropTypes.bool.isRequired,
    reasonBlocked: PropTypes.string.isRequired,
  };

  state = {
    validatingEmailWhileLoggedIn: false,
  };

  continueInPlace() {
    this.props.toggleAuthyDownDialog();
    this.props.toggle2FADialog();
  }
  
  continueToApp() {
    this.props.resetClaims()
      .then(() => {
        this.props.toggleAuthyDownDialog();
        this.props.history.push(this.props.text.Home.nav_path);
      });
  }

  rsaVerification() {
    this.props.resetClaims()
      .then(() => {
        this.props.toggleAuthyDownDialog();
        this.props.toggleRSADialog();
      });
  }

  componentDidMount() {
    const { isValid, location, text: { ValidateEmail } } = this.props;
    const storedVersion = localStorage.getItem('aa_version');
    if (!storedVersion || storedVersion !== window.appVersion) {
      clearState();
      localStorage.setItem('aa_version', window.appVersion);
    }
    if (location.pathname === ValidateEmail.nav_path && isValid) {
      // triggers a modal in VerifyEmail component if user is logged in and trying to paste an email validation code into the URL
      // user must validate in new tab so AccountBlock claim gets properly updated
      this.setState({ validatingEmailWhileLoggedIn: true });
    }
  }

  render() {
    const { 
      history, isValid, sidenavOpen, is2FADialogOpen, isRSADialogOpen,
      isAuthyDownDialogOpen, text, userAccess, uiPermissions,
      accountBlocked, reasonBlocked, toggleSplashHasDisplayed, splashHasDisplayed,
      isAccountListLoaded, isNewUser_AccessRole, documents,
    } = this.props;
    const token = sessionStorage.getItem('token');

    return (
      <div>
        <Header
          key={`Header_${isValid}`}
          pageTitle={!isValid && history.location.pathname === text.Home.nav_path ? '' : getTitle(history.location.pathname, userAccess)} // prevents the "Page Not Found" title from appearing while loading
        />
        
        <div className='hideOnPrint'>
          <Sidenav />
        </div>
        
        <NotificationsDrawer />
        <div className={styles.featuresContainer}>
          <main className={sidenavOpen ? styles.featuresChildrenShiftFull : isValid ? styles.featuresChildrenShiftMini : styles.featuresChildren}> {/* eslint-disable-line */}
            <ErrorBoundary>
              <ScrollToTop />
              <Switch>
                <PublicRoute path={text.Login.nav_path} component={Login} />
                <PublicRoute path={text.ForgotPassword.nav_path} component={ForgotPassword} />
                <PublicRoute path={text.Register.nav_path} component={Register} />
                <PublicRoute path={text.ResendEmail.nav_path} component={ResendEmail} />
                <PublicRoute path={text.ResetPassword.nav_path} component={ResetPassword} />
                <PublicRoute path={text.ValidateEmail.nav_path} component={ValidateEmail} key='ValidateEmail' />,
                <PublicRoute path={text.ForgotUsername.nav_path} component={ForgotUsername} />
                <PublicRoute path={text.SetupUser.nav_path} component={SetupUser} />
                <PublicRoute path={text.LpoaAccount.nav_path} component={LpoaAccount} />
                <PublicRoute path={text.InterestedPartiesActivation.nav_path} component={InterestedPartiesActivation} />

                <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.VerifyEmail} path={text.VerifyEmail.nav_path} component={VerifyEmail} validatingEmailWhileLoggedIn={this.state.validatingEmailWhileLoggedIn} isEmailMissing={reasonBlocked === REASONS_BLOCKED.MISSING_EMAIL_REQUIRED} />
                <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.Multifactor} path={text.Multifactor.nav_path} component={Multifactor} />
                <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.Signup} path={text.Signup.nav_path} exact component={Signup} key='Signup' />
                {accountBlocked && reasonBlocked === REASONS_BLOCKED.NO_BIRTHDATE && <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.AddBirthdate} path={text.AddBirthdate.nav_path} exact component={AddBirthdate} />}

                <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.Home} path={text.Home.nav_path} exact component={Home} key='Home' />
                {!uiPermissions.noBeneAccounts && <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.TransactionsHub} path={text.TransactionsHub.nav_path} component={TransactionHub} />}
                <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.MyInfo} path={text.MyInfo.nav_path} component={MyInfo} key='MyInfo' />
                <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.Lpoa} path={text.Lpoa.nav_path} component={Lpoa} key='ManageLpoa' />
                {uiPermissions.giftingEnabled && <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.Gifting} path={`${text.Gifting.nav_path}/:accountId`} component={Manage} />}
                {uiPermissions.giftingEnabled && <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.Gifting} path={text.Gifting.nav_path} component={Gifting} />}
                <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.Transactions} path={text.Transactions.nav_path(':accountId', 'transactionComponent')} component={Transactions} />
                <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.Accounts} path={`${text.AccountDetails.nav_path(':accountId')}/${text.AccountDetails.investment_path}`} component={ViewInvestmentPath} />
                <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.Accounts} path={text.Accounts.nav_path} render={Accounts} key='Accounts' /> { /*This route must appear lower in the list or it will be given routing priority over transactions */}
                {uiPermissions.canManageBankAccounts && <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.Payments} path={text.Payments.nav_path} component={Payments} />}
                <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.Documents} path={text.Documents.nav_path} exact component={Documents} />
                <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.PayrollContribution} path={text.PayrollContribution.nav_path} exact component={PayrollContribution} />
                {uiPermissions.canAddInterestedParties && <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.InterestedParties} path={text.InterestedParties.nav_path} component={InterestedParties} exact key='InterestedParties' />}
                <ProtectedRoute requiredAccessRights={ROUTE_ACCESS.PageNotFound} component={PageNotFound} key='PageNotFound' />

              </Switch>
            </ErrorBoundary>
          </main>
          {isValid && history.location.pathname === text.Home.nav_path
          && !accountBlocked && !reasonBlocked && splashHasDisplayed && isAccountListLoaded &&
            <AppTour splashHasDisplayed={splashHasDisplayed} />} {/* Needs to be on home route so new SSup users do not see app tour */}
            
          <div className={sidenavOpen ? styles.legalFooterShiftFull : isValid ? styles.legalFooterShiftMini : styles.legalFooter}> {/* eslint-disable-line */}
            <LegalFooter key={documents.programDescription} programDescriptionLink={documents.programDescription} />
          </div>
        </div>
        {isValid && <PreflightLoader key={this.props.preflightLoaderOpen} />}
        
        <Dialog fullScreen open={is2FADialogOpen} onClose={null} TransitionComponent={Transition}>
          <Authentication />
        </Dialog>

        <Dialog fullScreen open={isRSADialogOpen} onClose={null} TransitionComponent={Transition}>
          <RSA />
        </Dialog>
        
        {/* Splash Screen while accounts are loading */}
        {/* Splash should only display on valid user, after 2FA/RSA, or any other validation requirements */}
        {isValid && !accountBlocked && !reasonBlocked && !splashHasDisplayed &&
        <SplashScreen
          toggleSplashHasDisplayed={toggleSplashHasDisplayed}
          isAppLoading={!isAccountListLoaded && !isNewUser_AccessRole}
          splashHasDisplayed={splashHasDisplayed}
        />}

        <AuthyDown
          isOpen={isAuthyDownDialogOpen}
          continueToApp={() => this.continueToApp()}
          continueInPlace={() => this.continueInPlace()}
          logout={() => this.props.userLogout({ token })}
          rsaVerification={() => this.rsaVerification()}
          text={text}
          history={history}
        />
      </div>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) { // eslint-disable-line
  return <Slide direction='up' ref={ref} {...props} />;
});

export default withRouter(connect(select, {
  userLogout, 
  resetClaims,
  toggle2FADialog,
  toggleAuthyDownDialog,
  toggleRSADialog,
  toggleSplashHasDisplayed,
})(LanguageHOC(Navigation)));