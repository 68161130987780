import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
  TextField
} from '@mui/material';

import {
  notificationShow,
  LoadingOverlay,
} from '@frontend/common';

import {
  twoFactorTokenVerification,
  getPreferredMethod,
  savePreferredMethod,
  getTwoFactorToken
} from '../../actions';

import { userLogout } from 'components/AppRoot/Navigation/actions';
import { regMethodTypes } from '../../constants.js';

import styles from './styles.module.scss';

const select = (state) => ({
  RegistrationInfo: state.multifactor.RegistrationInfo,
  VerificationInfo: state.multifactor.VerificationInfo,
  preferredInfo: state.multifactor.preferredInfo,
  is2FARegistered: state.session.is2FARegistered,
});

export class RegisterPhone extends Component {

  static propTypes = {
    twoFactorTokenVerification: PropTypes.func.isRequired,
    getTwoFactorToken: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    getPreferredMethod: PropTypes.func.isRequired,
    savePreferredMethod: PropTypes.func.isRequired,
    RegistrationInfo: PropTypes.object.isRequired,
    VerificationInfo: PropTypes.object.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    closeWindow: PropTypes.func,
    is2FARegistered: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        btn_another_method: PropTypes.string,
        btn_calling: PropTypes.string,
        btn_call_again: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_learn_more: PropTypes.string,
        btn_resend_code: PropTypes.string,
        btn_sending: PropTypes.string,
        btn_submit_code: PropTypes.string,
        confirmation_path: PropTypes.string,
        err_verification_code_required: PropTypes.string,
        lbl_invalid_code: PropTypes.string,
        lbl_verification_code: PropTypes.string,
        msg_attempts_remaining: PropTypes.string,
        msg_powered_authy: PropTypes.string,
        msg_we_called: PropTypes.func,
        msg_we_sent: PropTypes.func,
        msg_resend_successful: PropTypes.string,
        nav_title: PropTypes.string,
        register_path: PropTypes.string,
        learn_more_url: PropTypes.string,
      }),
      Login: PropTypes.shape({
        msg_you_have_logged_out: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isLoading: false,
    hasVerificationCode: false,
    verificationCode: '',
    verificationCodeError: '',
    actionButton: false,
    attemptWarning: '',
    sendCodeBtn: this.props.text.Multifactor.btn_resend_code,
    callAgainBtn: this.props.text.Multifactor.btn_call_again
  };

  handleInput = name => e => {
    if (this.state.hasVerificationCode) {
      this.setState({
        hasVerificationCode: false,
        verificationCodeError: '',
        [name]: e.target.value
      });
    }
    else {
      this.setState({ [name]: e.target.value });
    }
  }

  doAgain = () => {
    const { RegistrationInfo, text: { Multifactor } } = this.props;

    if (RegistrationInfo.regMethod === regMethodTypes.CALL) {
      this.setState({
        callAgainBtn: Multifactor.btn_calling,
        actionButton: true,
        attemptWarning: ''
      });
    }
    else {
      this.setState({
        sendCodeBtn: Multifactor.btn_sending,
        actionButton: true,
        attemptWarning: ''
      });
    }

    setTimeout(() => {
      if (RegistrationInfo.regMethod === regMethodTypes.CALL) {
        this.setState({ callAgainBtn: Multifactor.btn_call_again, actionButton: false });
      }
      else {
        this.setState({ sendCodeBtn: Multifactor.btn_resend_code, actionButton: false });
      }
    }, 5000);

    this.props.getTwoFactorToken(RegistrationInfo.regMethod);
  }

  verifyCode = (e) => {
    e.preventDefault();
    const { text: { Multifactor } } = this.props;
    const { verificationCode } = this.state;
    if (verificationCode) {
      this.setState({ isLoading: true });
      Promise.all([
        this.props.twoFactorTokenVerification(verificationCode),
        this.props.getPreferredMethod()
      ]).then(() => {
        const { VerificationInfo, preferredInfo, RegistrationInfo } = this.props;
        if (VerificationInfo.Verified) {
          const params = {
            AlwaysRequired: preferredInfo.AlwaysRequired,
            PreferredMethod: RegistrationInfo.regMethod === regMethodTypes.CALL
              ? regMethodTypes.CALL
              : regMethodTypes.SMS
          };
          this.props.savePreferredMethod(params)
            .then(() => {
              this.props.history.push(Multifactor.confirmation_path);
            });
        }
        else {
          if (VerificationInfo.AttemptsRemaining === 0) {
            this.logout();
          }
          else if (VerificationInfo.AttemptsRemaining === 1) {
            this.setState({
              isLoading: false,
              attemptWarning: Multifactor.msg_attempts_remaining
            });
          }
          else {
            this.setState({
              isLoading: false,
              verificationCodeError: Multifactor.lbl_invalid_code
            });
          }
        }
      });
    }
    else {
      this.setState({
        hasVerificationCode: true,
        verificationCodeError: Multifactor.err_verification_code_required
      });
    }
  }

  logout = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .then(() => {
        this.props.notificationShow(this.props.text.Login.msg_you_have_logged_out, 'success');
      });
  }

  render() {
    const { RegistrationInfo, text: { Multifactor }, closeWindow, is2FARegistered } = this.props;
    const {
      isLoading, hasVerificationCode, verificationCode, verificationCodeError,
      attemptWarning, callAgainBtn, sendCodeBtn, actionButton,
    } = this.state;

    const lastFour = RegistrationInfo.PhoneNumber
      ? RegistrationInfo.PhoneNumber.substr(RegistrationInfo.PhoneNumber.length - 4, RegistrationInfo.PhoneNumber.length)
      : '';
    const sendConfirmMsg = RegistrationInfo.regMethod === regMethodTypes.CALL ? Multifactor.msg_we_called(lastFour) : Multifactor.msg_we_sent(lastFour);
    const actionType = RegistrationInfo.regMethod === regMethodTypes.CALL ? callAgainBtn : sendCodeBtn;

    return (
      <div className={styles.container}>
        <LoadingOverlay show={isLoading}>
          <h3>{Multifactor.lbl_verification_code}</h3>
          <div>{sendConfirmMsg}</div>
          <form
            id='authy-form'
            onSubmit={e => this.verifyCode(e)}
          >
            <div style={{ paddingTop: '10px' }}>
              <TextField
                error={Boolean(hasVerificationCode) || Boolean(verificationCodeError)}
                helperText={verificationCodeError}
                label={Multifactor.lbl_verification_code}
                onChange={this.handleInput('verificationCode')}
                value={verificationCode}
                variant='filled'
                autoFocus={true}
              />
            </div>
            <div>
              <Button
                color='primary'
                disabled={actionButton}
                style={{ width: '60%', marginTop: '20px' }}
                onClick={() => this.doAgain()}
              >
                {actionType}
              </Button>
            </div>
            <div>
              <Button
                color='primary'
                disabled={isLoading}
                style={{ width: '60%', marginTop: '20px' }}
                onClick={() => this.props.history.push(Multifactor.register_path)}
              >
                {Multifactor.btn_another_method}
              </Button>
            </div>
            <div>
              <Button
                color='primary'
                variant='text'
                disabled={isLoading}
                style={{ width: '60%', marginTop: '20px' }}
                onClick={is2FARegistered ? closeWindow : this.logout}
              >
                {Multifactor.btn_cancel}
              </Button>
            </div>
            <Button
              variant='contained'
              style={{ width: '60%', marginTop: '20px' }}
              type='submit'
            >
              {Multifactor.btn_submit_code}
            </Button>
          </form>
          <div className={styles.attemptWarning}>{attemptWarning}</div>
          <Button
            color='primary'
            variant='outlined'
            style={{ width: '60%', marginTop: '10px' }}
            onClick={() => window.open(Multifactor.learn_more_url, '_blank', 'noopener noreferrer')}
          >
            {Multifactor.btn_learn_more}
          </Button><br />
          <img src={require('../../PwddByAuthy.jpg')} alt={Multifactor.msg_powered_authy} />
        </LoadingOverlay>
      </div>
    );
  }
}

export default withRouter(connect(select, {
  twoFactorTokenVerification,
  getTwoFactorToken,
  getPreferredMethod,
  savePreferredMethod,
  userLogout,
  notificationShow
})(LanguageHOC(RegisterPhone)));