import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter, Route } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import { Paper } from '@mui/material';

import { My529Logo } from '@frontend/common';

import Register from './Registration/Register/index';
import RegisterPhone from './Registration/RegisterPhone/index';
import RegisterAuthenticator from './Registration/RegisterAuthenticator/index';
import RegisterAuthy from './Registration/RegisterAuthy/index';
import RegisterAuthyOnetouch from './Registration/RegisterAuthyOnetouch/index';
import VerifyIdentity from './Registration/VerifyIdentity/index';
import Confirmation from './Registration/Confirmation/index';
import Welcome from './Registration/Welcome/index';

import styles from './styles.module.scss';

export class Multifactor extends Component {

  static propTypes = {
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        nav_path: PropTypes.string,
        register_path: PropTypes.string,
        authenticator_path: PropTypes.string,
        phone_path: PropTypes.string,
        preferred_path: PropTypes.string,
        preferred_response_path: PropTypes.string,
        authy_path: PropTypes.string,
        authy_onetouch_path: PropTypes.string,
        confirmation_path: PropTypes.string,
        verify_identity_path: PropTypes.string,
      }),
    }).isRequired,
  };

  render() {
    const { text: { Multifactor } } = this.props;
    return (
      <div className={styles.container}>
        <Paper>
          <div className={styles.body}>
            <div className={styles.logo}><My529Logo /></div>
            <Switch>
              <Route exact path={Multifactor.nav_path} render={props => (<Welcome {...props} />)} />
              <Route exact path={Multifactor.register_path} render={props => (<Register {...props} />)} />
              <Route exact path={Multifactor.verify_identity_path} render={props => (<VerifyIdentity {...props} />)} />
              <Route exact path={Multifactor.phone_path} render={props => (<RegisterPhone {...props} />)} />
              <Route exact path={Multifactor.authenticator_path} render={props => (<RegisterAuthenticator {...props} />)} />
              <Route exact path={Multifactor.authy_path} render={props => (<RegisterAuthy {...props} />)} />
              <Route exact path={Multifactor.authy_onetouch_path} render={props => (<RegisterAuthyOnetouch {...props} />)} />
              <Route exact path={Multifactor.confirmation_path} render={props => (<Confirmation {...props} />)} />
            </Switch>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withRouter(LanguageHOC(Multifactor));