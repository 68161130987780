/*
*
* CreateIpAccount Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Checkbox,
  IconBtnTooltip,
  InfoIcon,
  LoadingOverlay,
  Modal,
  notificationShow,
  PasswordRequirements,
} from '@frontend/common';
import {
  Button,
  Accordion,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@mui/material';
import { passwordRequirementsGet } from 'components/AppRoot/StaticResources/actions';
import { activateIpAccount } from 'components/Features/protected/InterestedParties/actions.js';
import * as validator from 'utils/helpers/form_validation';

import styles from './styles.module.scss';
import parentStyles from '../styles.module.scss';

const select = (state) => ({
  emailAddress: state.interestedParties.activationInfo.emailAddress,
  passwordRequirements: state.static.passwordRequirements,
});


export class CreateIpAccount extends React.Component {

  static propTypes = {
    activateIpAccount: PropTypes.func.isRequired,
    csaPin: PropTypes.string, // cannot be required because the API returns it as null
    emailAddress: PropTypes.string.isRequired,
    notificationShow: PropTypes.func.isRequired,
    passwordRequirements: PropTypes.array.isRequired,
    passwordRequirementsGet: PropTypes.func.isRequired,
    redirectToLogin: PropTypes.func.isRequired,
    showCreateAccountForm: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      InterestedPartiesActivation: PropTypes.shape({
        btn_create: PropTypes.string,
        btn_i_agree: PropTypes.string,
        btn_read_terms: PropTypes.string,
        err_password_criteria: PropTypes.string,
        err_password_match: PropTypes.string,
        err_required_field: PropTypes.string,
        err_terms_agree: PropTypes.string,
        head_create_account: PropTypes.string,
        head_terms: PropTypes.string,
        lbl_email: PropTypes.string,
        lbl_firstname: PropTypes.string,
        lbl_lastname: PropTypes.string,
        lbl_password1: PropTypes.string,
        lbl_password2: PropTypes.string,
        lbl_pin: PropTypes.string,
        lbl_username: PropTypes.string,
        msg_change_email_when_logged_in: PropTypes.string,
        msg_ip_activated: PropTypes.string,
        text_terms: PropTypes.string,
        text_toggle_password_vis: PropTypes.string,
      })
    }).isRequired,
    toggleFormVisibility: PropTypes.func.isRequired,
  };

  state = {
    apiErrors: [],
    formErrors: {},
    loading: false,
    newIp: {
      firstName: '',
      lastName: '',
      password1: '',
      password2: '',
      pin: '',
      userName: '',
    },
    passwordIsValid: false,
    passwordReqsVisible: false,
    showPassword1: false,
    showPassword2: false,
    termsChecked: false,
    termsModalOpen: false,
  };

  createIpAccount = e => {
    e.preventDefault();

    const formIsValid = this.validateForm();
    
    if (formIsValid) {
      this.setState({ loading: true });
      const guid = new URLSearchParams(window.location.search.toLowerCase()).get('request');

      this.props.activateIpAccount(guid, this.state.newIp)
        .then(() => {
          this.setState({ loading: false });
          this.props.redirectToLogin();
          this.props.notificationShow(this.props.text.InterestedPartiesActivation.msg_ip_activated, 'success');
        })
        .catch(res => {
          if (res.payload.data && res.payload.data.some(err => err.Field)) {
            this.setState({
              apiErrors: res.payload.data,
              loading: false,
            }, () => this.validateForm());
          }
          else {
            this.props.redirectToLogin();
          }
        });
    }
  }

  onInputChange = e => {
    this.setState({
      formErrors: {
        ...this.state.formErrors,
        [e.target.name]: '',
      },
      newIp: {
        ...this.state.newIp,
        [e.target.name]: e.target.value,
      },
    });
  }

  togglePasswordVisibility = (passwordToShow) => {
    this.setState({ [passwordToShow]: !this.state[passwordToShow] });
  }

  validateForm = () => {
    const { apiErrors, newIp, passwordIsValid, termsChecked, } = this.state;
    const { InterestedPartiesActivation } = this.props.text;
    const formErrors = {};

    formErrors.firstName = validator.firstNameValidator(newIp.firstName);
    formErrors.lastName = validator.lastNameValidator(newIp.lastName);
    formErrors.userName = validator.userNameValidator(newIp.userName);
    formErrors.pin = newIp.pin ? '' : InterestedPartiesActivation.err_required_field;
    formErrors.terms = termsChecked ? '' : InterestedPartiesActivation.err_terms_agree;

    // Password validation
    if (!passwordIsValid) {
      formErrors.password1 = InterestedPartiesActivation.err_password_criteria;
    }
    if (!newIp.password1) {
      formErrors.password1 = InterestedPartiesActivation.err_required_field;
    }
    if (!newIp.password2) {
      formErrors.password2 = InterestedPartiesActivation.err_required_field;
    }
    if (newIp.password1 !== newIp.password2) {
      formErrors.password2 = InterestedPartiesActivation.err_password_match;
    }

    if (apiErrors.length > 0) {
      apiErrors.map(err => formErrors[err.Field] = err.Message);
    }

    this.setState({
      apiErrors: [],
      formErrors
    });

    return Object.keys(formErrors).every(error => formErrors[error] === ''); // returns true if no errors
  }

  componentDidMount() {
    if (this.props.passwordRequirements.length === 0) {
      this.props.passwordRequirementsGet();
    }
    if (this.props.csaPin) {
      this.setState({
        newIp: {
          ...this.state.newIp,
          pin: this.props.csaPin
        }
      });
    }
  }

  render() {
    const { csaPin, emailAddress, passwordRequirements, showCreateAccountForm, text: { InterestedPartiesActivation } } = this.props;
    const { formErrors, loading, newIp, passwordReqsVisible, showPassword1, showPassword2, termsChecked, termsModalOpen, } = this.state;
    return (
      <React.Fragment>
        <form
          className={parentStyles.createAccountForm}
          onSubmit={e => !showCreateAccountForm ? this.activateIpAccount(e) : e.preventDefault()} // prevents form from submitting before its visible
        >
          <h2>{InterestedPartiesActivation.head_create_account}</h2>

          <div className={showCreateAccountForm ? parentStyles.showFormContainer : parentStyles.hideFormContainer}>
            <div style={{ display: 'flex', flexWrap: 'no-wrap', alignItems: 'center' }}>
              <TextField
                disabled // always disabled - user can only view email here
                fullWidth
                label={InterestedPartiesActivation.lbl_email}
                value={emailAddress}
                variant='filled'
              />
              <InfoIcon message={InterestedPartiesActivation.msg_change_email_when_logged_in} />
            </div>

            <TextField
              disabled={!showCreateAccountForm}
              error={Boolean(formErrors.firstName)}
              fullWidth
              helperText={formErrors.firstName}
              label={InterestedPartiesActivation.lbl_firstname}
              name='firstName'
              onChange={this.onInputChange}
              value={newIp.firstName}
              variant='filled'
            />

            <TextField
              disabled={!showCreateAccountForm}
              error={Boolean(formErrors.lastName)}
              fullWidth
              helperText={formErrors.lastName}
              label={InterestedPartiesActivation.lbl_lastname}
              name='lastName'
              onChange={this.onInputChange}
              value={newIp.lastName}
              variant='filled'
            />
                          
            <TextField
              disabled={!showCreateAccountForm || Boolean(csaPin)}
              error={Boolean(formErrors.pin)}
              fullWidth
              helperText={formErrors.pin}
              label={InterestedPartiesActivation.lbl_pin}
              name='pin'
              onChange={this.onInputChange}
              value={csaPin ? csaPin : newIp.pin}
              variant='filled'
            />

            <TextField
              disabled={!showCreateAccountForm}
              error={Boolean(formErrors.userName)}
              fullWidth
              helperText={formErrors.userName}
              label={InterestedPartiesActivation.lbl_username}
              name='userName'
              onChange={this.onInputChange}
              value={newIp.userName}
              variant='filled'
            />

            <TextField
              disabled={!showCreateAccountForm}
              error={Boolean(formErrors.password1)}
              fullWidth
              helperText={formErrors.password1}
              InputProps={{
                endAdornment: <InputAdornment position='end'>
                  <IconBtnTooltip
                    icon={showPassword1 ? 'visibility' : 'visibility_off'}
                    onClick={() => this.togglePasswordVisibility('showPassword1')}
                    title={InterestedPartiesActivation.text_toggle_password_vis}
                  />
                </InputAdornment>,
              }}
              label={InterestedPartiesActivation.lbl_password1}
              name='password1'
              onBlur={() => this.setState({ passwordReqsVisible: false })}
              onChange={this.onInputChange}
              onFocus={() => this.setState({ passwordReqsVisible: true })}
              style={{ boxShadow: 'none' }}
              type={showPassword1 ? 'text' : 'password'}
              value={newIp.password1}
              variant='filled'
            />


            <Accordion
              sx={{
                boxShadow: 'none',
                '&:before': {
                  backgroundColor: 'transparent'
                },
              }}
              expanded={passwordReqsVisible}
            >
              <TextField
                autoComplete='new-password'
                disabled={!showCreateAccountForm}
                error={Boolean(formErrors.password2)}
                fullWidth
                helperText={formErrors.password2}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>
                    <IconBtnTooltip
                      icon={showPassword2 ? 'visibility' : 'visibility_off'}
                      onClick={() => this.togglePasswordVisibility('showPassword2')}
                      title={InterestedPartiesActivation.text_toggle_password_vis}
                    />
                  </InputAdornment>,
                }}
                label={InterestedPartiesActivation.lbl_password2}
                name='password2'
                onChange={this.onInputChange}
                type={showPassword2 ? 'text' : 'password'}
                value={newIp.password2}
                variant='filled'
              />
              <div className={styles.passwordRequirements}>
                <PasswordRequirements
                  onPasswordCheck={passwordIsValid => this.setState({ passwordIsValid })}
                  password={newIp.password1}
                  passwordRequirements={passwordRequirements}
                />
              </div>
            </Accordion>

            <div className={styles.termsAndConditionsContainer}>
              <Checkbox
                checked={termsChecked}
                color='primary'
                disabled={!showCreateAccountForm}
                error={formErrors.terms}
                label={InterestedPartiesActivation.btn_i_agree}
                onChange={() => this.setState({ formErrors: { ...formErrors, terms: '' }, termsChecked: !termsChecked })}
              />
              {showCreateAccountForm && <Button onClick={() => this.setState({ termsModalOpen: true })}>{InterestedPartiesActivation.btn_read_terms}</Button>}
              {formErrors.terms && <FormHelperText error={Boolean(formErrors.terms)}>{formErrors.terms}</FormHelperText>}
            </div>
          </div>

          <div className={parentStyles.button}>
            <LoadingOverlay show={loading} width='100%'>
              <Button
                disabled={loading}
                fullWidth
                onClick={(e) => showCreateAccountForm ? this.createIpAccount(e) : this.props.toggleFormVisibility()}
                type='submit'
                variant='contained'
              >
                {InterestedPartiesActivation.btn_create}
              </Button>
            </LoadingOverlay>
          </div>
        </form>

        <Modal
          onCloseModal={() => this.setState({ termsModalOpen: false })}
          show={termsModalOpen}
          title={InterestedPartiesActivation.head_terms}
        >
          {InterestedPartiesActivation.text_terms}
        </Modal>
      </React.Fragment>
    );
  }
}


export default (connect(select, {
  activateIpAccount,
  notificationShow,
  passwordRequirementsGet,
})(LanguageHOC(CreateIpAccount)));
