import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
} from '@mui/material';

import PwddByAuthy from '../../PwddByAuthy.jpg';

import styles from './styles.module.scss';

export class RegisterAuthenticator extends Component {

  static propTypes = {
    onContinue: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        warning_other_authenticator: PropTypes.shape({
          title: PropTypes.string.isRequired,
          paragraph_1: PropTypes.string.isRequired,
          paragraph_2: PropTypes.string.isRequired,
          paragraph_3: PropTypes.string.isRequired
        }).isRequired,
        btn_cancel: PropTypes.string,
        btn_learn_more: PropTypes.string,
        msg_powered_authy: PropTypes.string,
        learn_more_url: PropTypes.string,
      }),
    }).isRequired,
  };

  render() {
    const { text: { Multifactor }, onContinue, onCancel } = this.props;

    return (
      <div style={{ textAlign: 'center' }}>
        <h2>{Multifactor.warning_other_authenticator.title}</h2>
        <div className={styles.container}>
          <div className={styles.text_paragraph}>{Multifactor.warning_other_authenticator.paragraph_1}</div>
          <div className={styles.text_paragraph}>{Multifactor.warning_other_authenticator.paragraph_2}</div>
          <div className={styles.text_paragraph}>{Multifactor.warning_other_authenticator.paragraph_3}</div>          
        </div>
        <Button
          variant='contained'
          style={{ width: '60%', marginTop: '20px' }}
          onClick={onContinue}
        >
          continue
        </Button>

        <Button
          color='primary'
          variant='outlined'
          style={{ width: '60%', marginTop: '20px' }}
          onClick={onCancel}
        >
          cancel
        </Button>

        <Button
          color='primary'
          variant='outlined'
          style={{ width: '60%', marginTop: '10px' }}
          onClick={() => window.open(Multifactor.learn_more_url, '_blank', 'noopener noreferrer')}
        >
          {Multifactor.btn_learn_more}
        </Button><br />
        <img src={PwddByAuthy} alt={Multifactor.msg_powered_authy} />
      </div>
    );
  }
}

export default LanguageHOC(RegisterAuthenticator);