import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import { Button } from '@mui/material';

export class Welcome extends Component {

  static propTypes = {
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        btn_get_started: PropTypes.string,
        btn_learn_more: PropTypes.string,
        msg_2FA: PropTypes.string,
        msg_2FA_explanation: PropTypes.string,
        msg_powered_authy: PropTypes.string,
        nav_title: PropTypes.string,
        verify_identity_path: PropTypes.string,
        learn_more_url: PropTypes.string
      }),
    }).isRequired,
  };

  state = {
    learnMoreOpen: false,
  };

  render() {
    const { text: { Multifactor } } = this.props;
    return (
      <div style={{ textAlign: 'center' }}>
        <img src={ require('../../2FAGraphic.png') } alt={Multifactor.nav_title} width='50%' />
        <h3>{Multifactor.msg_2FA}</h3>
        <p>{Multifactor.msg_2FA_explanation}</p>
        <Button
          variant='contained'
          style={{ width: '60%' }}
          onClick={() => this.props.history.push(Multifactor.verify_identity_path)}
        >
          {Multifactor.btn_get_started}
        </Button>
        <Button
          color='primary'
          variant='outlined'
          style={{ width: '60%', marginTop: '10px' }}
          onClick={() => window.open(Multifactor.learn_more_url, '_blank', 'noopener noreferrer')}
        >
          {Multifactor.btn_learn_more}
        </Button><br />
        <img src={ require('../../PwddByAuthy.jpg') } alt={Multifactor.msg_powered_authy} />
      </div>
    );
  }
}

export default withRouter(LanguageHOC(Welcome));