import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty, cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import LanguageHOC from 'utils/translations/LanguageHOC';
import FormWrapper from '../FormWrapper';

import {
  Button,
  TextField,
  InputAdornment,
  Accordion,
  Icon,
} from '@mui/material';

import {
  LoadingOverlay,
  notificationShow,
  DatePicker,
  SsnInput,
  PasswordRequirements,
} from '@frontend/common';

import { INIT_FORM_ERRORS } from '../constants';
import { GETRecaptchaToken } from 'utils/helpers/api_handler';
import { passwordRequirementsGet } from 'components/AppRoot/StaticResources/actions';
import { registerUserSync, verifyRecaptcha } from 'components/AppRoot/Navigation/actions';

import {
  agentSearch,
  agentUpdate
} from '../actions';

import * as validator from 'utils/helpers/form_validation';

const select = (state) => ({
  MaximumAge: state.static.environmentVars.MaximumAge,
  MinimumAccountOwnerAge: state.static.environmentVars.MinimumAccountOwnerAge,
  passwordRequirements: state.static.passwordRequirements,
  agent: state.registrationtype.agent,
  updatedAgent: state.registrationtype.updatedAgent,
  supportNumber: state.static.environmentVars.SupportPhoneNumber,
  recaptchaToken: state.session.userDetails.recaptchaToken,
  recaptchaVerified: state.session.userDetails.recaptchaVerified,
  documents: state.static.documents,
});

export class SetupUser extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    agentSearch: PropTypes.func.isRequired,
    agentUpdate: PropTypes.func.isRequired,
    registerUserSync: PropTypes.func.isRequired,
    verifyRecaptcha: PropTypes.func.isRequired,
    passwordRequirementsGet: PropTypes.func.isRequired,
    passwordRequirements: PropTypes.array.isRequired,
    agent: PropTypes.object.isRequired,
    updatedAgent: PropTypes.object.isRequired,
    MaximumAge: PropTypes.number.isRequired,
    MinimumAccountOwnerAge: PropTypes.number.isRequired,
    recaptchaToken: PropTypes.string.isRequired,
    recaptchaVerified: PropTypes.bool.isRequired,
    supportNumber: PropTypes.string.isRequired,
    text: PropTypes.shape({
      SetupUser: PropTypes.shape({
        btn_continue: PropTypes.string,
        btn_create: PropTypes.string,
        err_password_match: PropTypes.string,
        err_required: PropTypes.string,
        lbl_dob: PropTypes.string,
        lbl_email: PropTypes.string,
        lbl_password: PropTypes.string,
        lbl_repeat_password: PropTypes.string,
        lbl_ssn: PropTypes.string,
        lbl_username: PropTypes.string,
        msg_agent_not_found: PropTypes.string,
        msg_recaptcha_language: PropTypes.string,
        nav_title: PropTypes.string,
      }),
      ForgotUsername: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
      ResendEmail: PropTypes.shape({
        nav_path: PropTypes.string,
      })
    }).isRequired,
  };

  state = {
    loading: false,
    pageState: 0,
    SocialSecurityNumber: '',
    BirthDate: '',
    UserName: '',
    EmailAddress: '',
    PasswordOne: '',
    PasswordTwo: '',
    isPasswordVisible: false,
    isPaswordConfirmVisible: false,
    isPasswordValid: false,
    isPasswordReqsVisible: false,
    agentHasEmail: true,
    canContinue: false,
    formErrors: INIT_FORM_ERRORS
  };

  handleInput = name => e => {
    const { formErrors } = cloneDeep(this.state);
    formErrors[name] = '';
    this.setState({ [name]: e.target.value, formErrors });
  }

  handleCustomInputs = e => {
    const { formErrors } = cloneDeep(this.state);
    formErrors[e.target.name] = '';
    this.setState({ [e.target.name]: e.target.value, formErrors });
  }

  validateAgent = e => {
    e.preventDefault();
    const { SocialSecurityNumber, BirthDate, formErrors } = this.state;
    const { MaximumAge, MinimumAccountOwnerAge, text: { SetupUser } } = this.props;
    formErrors.SocialSecurityNumber = validator.socialSecurityNumValidator(SocialSecurityNumber);
    formErrors.BirthDate = validator.accountOwnerBirthdateValidator(BirthDate, MinimumAccountOwnerAge, MaximumAge);

    if (Object.values(formErrors).some(error => error !== '')) {
      this.setState({ formErrors });
    }
    else {
      const params = {
        SocialSecurityNumber,
        BirthDate: dayjs(BirthDate).format('YYYY-MM-DD'),
      };
      this.setState({ loading: true });
      this.props.agentSearch(params)
        .then(() => {
          this.setState({ loading: false });
          const { agent } = this.props;
          if (isEmpty(agent)) { // no account  
            this.setState({ SocialSecurityNumber: '', BirthDate: '' });
            this.props.notificationShow(SetupUser.msg_agent_not_found, 'error');
          }
          else {
            if (agent.HasLogin === 0) {
              if (agent.EmailAddress) {
                this.setState({ pageState: 1, EmailAddress: agent.EmailAddress });
              }
              else {
                this.setState({ pageState: 1, agentHasEmail: false });
              }
            }
            else { // has a login
              this.props.history.push(this.props.text.ForgotUsername.nav_path);
            }
          }
        });
    }
  }

  updateAgent = e => {
    e.preventDefault();
    const { UserName, SocialSecurityNumber, BirthDate, EmailAddress, PasswordOne, PasswordTwo, formErrors } = cloneDeep(this.state);
    const { text: { SetupUser } } = this.props;
    formErrors.UserName = validator.userNameValidator(UserName);
    formErrors.EmailAddress = validator.emailValidator(EmailAddress);
    formErrors.PasswordOne = PasswordOne === '' ? SetupUser.err_required : '';
    formErrors.PasswordTwo = PasswordTwo !== ''
      ? PasswordOne !== PasswordTwo
        ? SetupUser.err_password_match
        : ''
      : SetupUser.err_required;
    if (Object.values(formErrors).some(error => error !== '')) {
      this.setState({ formErrors });
    }
    else {
      const params = {
        UserName,
        EmailAddress,
        PasswordOne,
        PasswordTwo,
        SocialSecurityNumber,
        BirthDate: dayjs(BirthDate).format('YYYY-MM-DD')
      };
      this.setState({ loading: true });
      this.props.agentUpdate(params)
        .then(() => {
          this.props.registerUserSync(this.props.updatedAgent);
          this.props.history.push(this.props.text.ResendEmail.nav_path);
        })
        .catch(errors => {
          const apiErrors = errors.payload.data;
          if (Array.isArray(apiErrors)) { // inline errors
            apiErrors.forEach(apiError => {
              if (Object.keys(formErrors).some(formError => formError === apiError.Field)) {
                formErrors[apiError.Field] = apiError.Message;
              }
            });
            this.setState({ loading: false, formErrors });
          }
          else {
            this.props.notificationShow(apiErrors, 'error');
            this.setState({ loading: false, formErrors: INIT_FORM_ERRORS });
          }
        });
    }
  }

  recaptchaVerify = () => {
    GETRecaptchaToken('registrationtype')
      .then(token => {
        if (token !== this.props.recaptchaToken) {
          this.props.verifyRecaptcha(token)
            .then((response) => {
              if (!this.props.recaptchaVerified) {
                const warningMessage = response.payload.data['error-codes'][0];
                this.setState({ canContinue: true });
                this.props.notificationShow(warningMessage, 'warning');
              }
            });
        }
      });
  }

  componentDidMount() {
    if (this.props.passwordRequirements.length === 0) {
      this.setState({ loading: true });
      this.props.passwordRequirementsGet()
        .then(() => this.setState({ loading: false }))
        .catch(() => this.setState({ loading: false }));
    }
    this.recaptchaVerify();
  }

  render() {
    const {
      MaximumAge, MinimumAccountOwnerAge,
      text: { SetupUser }, passwordRequirements,
    } = this.props;

    const {
      loading, formErrors, SocialSecurityNumber, BirthDate, UserName, EmailAddress, agentHasEmail,
      PasswordOne, PasswordTwo, isPasswordVisible, isPasswordConfirmVisible, isPasswordReqsVisible,
      canContinue, pageState
    } = this.state;

    return (
      <>
        {loading ?
          <LoadingOverlay show={loading} width='100%' />
          :
          <FormWrapper>
            {pageState === 0 &&

              <form
                id='continue'
                onSubmit={this.validateAgent}
              >
                <SsnInput
                  disabled={loading}
                  error={Boolean(formErrors.SocialSecurityNumber)}
                  errorText={formErrors.SocialSecurityNumber}
                  fullWidth
                  label={`${SetupUser.lbl_ssn} * `}
                  onChange={({ value }) => this.handleCustomInputs({ target: { name: 'SocialSecurityNumber', value } })}
                  value={SocialSecurityNumber}
                  variant='filled'
                />
                <DatePicker
                  error={Boolean(formErrors.BirthDate)}
                  fullWidth
                  helperText={formErrors.BirthDate}
                  variant='filled'
                  key='birthDate'
                  label={`${SetupUser.lbl_dob} *`}
                  maxDate={dayjs().subtract(MinimumAccountOwnerAge, 'year')}
                  minDate={dayjs().subtract(MaximumAge, 'year')}
                  onChange={e => this.handleCustomInputs({ target: { name: 'BirthDate', value: e ? e.toDate() : e } })}
                  openTo='year'
                  value={BirthDate || null}
                  disabled={loading}
                />
                <Button
                  id='btn_continue'
                  variant='contained'
                  color='primary'
                  style={{ marginTop: '15px' }}
                  type='submit'
                  disabled={loading || canContinue}
                  fullWidth
                >
                  {SetupUser.btn_continue}
                </Button>
              </form>
            }


            {pageState === 1 &&
              <form
                id='create'
                onSubmit={this.updateAgent}
              >
                <TextField
                  disabled={loading}
                  error={Boolean(formErrors.UserName)}
                  fullWidth
                  helperText={formErrors.UserName}
                  label={`${SetupUser.lbl_username} *`}
                  onChange={this.handleInput('UserName')}
                  value={UserName}
                />
                <TextField
                  error={Boolean(formErrors.EmailAddress)}
                  fullWidth
                  helperText={formErrors.EmailAddress}
                  id='EmailAddress'
                  label={`${SetupUser.lbl_email} *`}
                  name='EmailAddress'
                  onChange={this.handleInput('EmailAddress')}
                  value={EmailAddress}
                  disabled={agentHasEmail}
                />
                <TextField
                  error={Boolean(formErrors.PasswordOne)}
                  fullWidth
                  helperText={formErrors.PasswordOne}
                  label={`${SetupUser.lbl_password} *`}
                  name='PasswordOne'
                  onFocus={() => this.setState({ isPasswordReqsVisible: true })}
                  onBlur={() => this.setState({ isPasswordReqsVisible: false })}
                  onChange={this.handleInput('PasswordOne')}
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={PasswordOne}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>
                      <Icon
                        className='material-icons'
                        style={{ marginRight: '15px', cursor: 'pointer' }}
                        onClick={() => this.setState({ isPasswordVisible: !isPasswordVisible })}
                      >visibility</Icon>
                    </InputAdornment>
                  }}
                />
                <Accordion
                  sx={{
                    boxShadow: 'none',
                  }}
                  expanded={isPasswordReqsVisible}
                >
                  <TextField
                    style={{ marginBottom: '20px' }}
                    error={Boolean(formErrors.PasswordTwo)}
                    fullWidth
                    helperText={formErrors.PasswordTwo}
                    label={`${SetupUser.lbl_repeat_password} *`}
                    name='PasswordTwo'
                    onChange={this.handleInput('PasswordTwo')}
                    type={isPasswordConfirmVisible ? 'text' : 'password'}
                    value={PasswordTwo}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>
                        <Icon
                          className='material-icons'
                          style={{ marginRight: '15px', cursor: 'pointer' }}
                          onClick={() => this.setState({ isPasswordConfirmVisible: !isPasswordConfirmVisible })}
                        >visibility</Icon>
                      </InputAdornment>
                    }}
                  />
                  <PasswordRequirements
                    password={PasswordOne}
                    passwordRequirements={passwordRequirements}
                    onPasswordCheck={(isPasswordValid) => this.setState({ isPasswordValid })}
                  />
                </Accordion>
                <Button
                  id='btn_create'
                  variant='contained'
                  color='primary'
                  style={{ marginTop: '15px' }}
                  type='submit'
                  disabled={loading}
                  fullWidth
                >
                  {SetupUser.btn_create}
                </Button>
              </form>}
          </FormWrapper>
        }
      </>
    );
  }
}

export default withRouter((connect(select, {
  notificationShow,
  passwordRequirementsGet,
  agentSearch,
  agentUpdate,
  registerUserSync,
  verifyRecaptcha
})(LanguageHOC(SetupUser))));
