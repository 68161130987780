import { cloneDeep } from 'lodash';

import {
  DEVICE_TOKEN_CREATE,
  DEVICE_TOKEN_GET,
  SECURITY_QESTION_GET,
  SECURITY_QESTIONS_ANSWER,
  RSA_STATUS_GET,
  RSA_VERIFY_BIRTHDAY,
  RSA_QESTIONS_GET,
  RSA_ANSWERS,
  TWO_FACTOR_REGISTRATION,
  TWO_FACTOR_REGISTRATION_UPDATE,
  TWO_FACTOR_TOKEN_VERIFICATION,
  TWO_FACTOR_TOKEN,
  TWO_FACTOR_QRCODES,
  TWO_FACTOR_PUSH,
  TWO_FACTOR_PUSH_VERIFICATION,
  TWO_FACTOR_PREFERRED_METHOD,
  TWO_FACTOR_SAVE_METHOD
} from './constants';

const initialState = {
  isIdentityVerified: false,
  isSecurityQuestionsLockedOut: false,
  isRSALockedOut: false,
  challengeQuestion: '',
  challengeQuestionCount: 0,
  RSADOB: null,
  isDOBValid: false,
  RSAQuestionSet: {},
  RegistrationInfo: {},
  VerificationInfo: {},
  QRCodeInfo: {},
  AuthyInfo: {},
  pushInfo: {},
  pushVerificationInfo: {},
  preferredInfo: {},
  regMethod: '',
  deviceToken: ''
};

function MultifactorReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {
    
    case DEVICE_TOKEN_CREATE: {
      newState.deviceToken = action.payload.data.Token;
      return newState;
    }
    
    case DEVICE_TOKEN_GET: {
      const payload = action.payload.data;
      if (payload) {
        newState.isIdentityVerified = action.payload.data.IdentityVerified;
      }
      return newState;
    }

    /*
    If the users fails all 4 attempts at the Security Questions and closes tab or logs out before attempting to answer RSA, 
    if the user logs in again within 30 min, they will be taken straight to RSA questions. If they log back in after 
    30 min the will start the identity verification process from the beginning (check for device id -> security questions -> RSA).
    */
    case SECURITY_QESTION_GET: {
      const payload = action.payload.data;
      if (payload) {
        newState.challengeQuestion = payload.Question;
        newState.isIdentityVerified = payload.IdentityVerified;
        newState.isSecurityQuestionsLockedOut = payload.LockedOut;
        newState.challengeQuestionCount = ++newState.challengeQuestionCount;
      }
      return newState;
    }

    /*
    Incorrect answer: identity verified = false, locked out false, and new challengeQuestion
    correct answer: identity verified = true, locked out = false, and challengeQuestion would be null wrong answer AND locked out: 
    */
    case SECURITY_QESTIONS_ANSWER: {
      const payload = action.payload.data;
      if (payload) {
        newState.isSecurityQuestionsLockedOut = payload.LockedOut;
        newState.isIdentityVerified = payload.IdentityVerified;
        newState.challengeQuestion = payload.Question;
      }
      return newState;
    }

    case RSA_STATUS_GET: {
      const payload = action.payload.data;
      if (payload) {
        newState.isRSALockedOut = payload.Status === 'LockedOut';
      }
      return newState;
    }
    
    case RSA_VERIFY_BIRTHDAY: {
      const payload = action.payload.data;
      if (payload) {
        newState.isDOBValid = payload.IsValid;
        newState.isRSALockedOut = payload.LockedOut;
      }
      return newState;
    }
    
    /*
    If IdentityVerified is false = locked out and try again in 24 hours
    But, in reality they can try again right away. 
    */
    case RSA_QESTIONS_GET: {
      const payload = action.payload.data;
      if (payload) {
        newState.isRSALockedOut = payload.LockedOut;
        newState.IdentityVerified = payload.IdentityVerified;
        newState.RSAQuestionSet = {
          QuestionSetId: payload.QuestionSetId,
          TransactionId: payload.TransactionId,
          Questions: payload.Questions,
          ErrorMessages: payload.ErrorMessages,
        };
      }
      return newState;
    }

    /*
    IdentityVerified true or false. (LockedOut is irrelevant in this case)
    - true, done with idenity verifiction
    - false, get more questions in this payload OR no questions (no questions, means you failed)
    */
    case RSA_ANSWERS: {
      const payload = action.payload.data;
      if (payload) {
        newState.RSAQuestionSet.Questions = payload.Questions;
        newState.isIdentityVerified = payload.IdentityVerified;
        newState.isRSALockedOut = payload.LockedOut;
        newState.RSAQuestionSet.ErrorMessages = payload.ErrorMessages;
      }
      return newState;
    }

    case TWO_FACTOR_REGISTRATION_UPDATE:
    case TWO_FACTOR_REGISTRATION: {
      newState.RegistrationInfo = action.payload.data;
      newState.RegistrationInfo.regMethod = action.meta.regMethod;
      return newState;
    }

    case TWO_FACTOR_TOKEN_VERIFICATION: {
      newState.VerificationInfo = action.payload.data;
      return newState;
    }

    case TWO_FACTOR_TOKEN: {
      return newState;
    }

    case TWO_FACTOR_QRCODES: {
      newState.QRCodeInfo = action.payload.data;
      return newState;
    }

    case TWO_FACTOR_PUSH: {
      newState.pushInfo = action.payload.data;
      return newState;
    }

    case TWO_FACTOR_PUSH_VERIFICATION: {
      newState.pushVerificationInfo = action.payload.data;
      return newState;
    }

    case TWO_FACTOR_PREFERRED_METHOD: {
      newState.preferredInfo = action.payload.data;
      return newState;
    }

    case TWO_FACTOR_SAVE_METHOD: {
      newState.preferredInfo = action.payload.data;
      return newState;
    }

    default:
      return state;
  }
}

export default MultifactorReducer;