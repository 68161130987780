/*
*
* Login Component
*
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import parse from 'html-react-parser';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
  Divider,
  TextField,
  InputAdornment,
} from '@mui/material';

import { 
  Visibility, 
  VisibilityOff,
} from '@mui/icons-material';

import {
  LoadingOverlay,
  StyledLink,
  allNotificationsHide,
  notificationShow,
  IconBtnTooltip,
} from '@frontend/common';

import FormWrapper from '../FormWrapper';
import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import { GETRecaptchaToken } from 'utils/helpers/api_handler';
import { getRegisteredDevice } from 'utils/helpers/registered_device_handler';
import { userLogin, verifyRecaptcha } from 'components/AppRoot/Navigation/actions';
import { getThreatMetrixVars } from 'components/AppRoot/StaticResources/actions.js';

import styles from './styles.module.scss';

const select = (state) => ({
  recaptchaToken: state.session.userDetails.recaptchaToken,
  recaptchaVerified: state.session.userDetails.recaptchaVerified,
  supportNumber: state.static.environmentVars.SupportPhoneNumber,
  threatMetrixVars: state.static.threatMetrixVars,
  loadingPublicVars: state.static.loadingPublicVars,
  webMessages: state.static.webMessages,
});

export class Login extends Component {

  static propTypes = {
    webMessages: PropTypes.object.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    supportNumber: PropTypes.string.isRequired,
    getThreatMetrixVars: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    recaptchaToken: PropTypes.string.isRequired,
    recaptchaVerified: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Login: PropTypes.shape({
        btn_create_account: PropTypes.string,
        btn_fa_login_link: PropTypes.string,
        btn_login: PropTypes.string,
        err_password: PropTypes.string,
        password_length: PropTypes.string,
        err_username: PropTypes.string,
        username_length: PropTypes.string,
        lbl_password: PropTypes.string,
        lbl_username: PropTypes.string,
        msg_recaptcha_language: PropTypes.string,
        nav_password: PropTypes.string,
        nav_username: PropTypes.string,
        nav_path: PropTypes.string,
        nav_title: PropTypes.string,
        btn_toggle_password: PropTypes.string,
      }),
      ForgotPassword: PropTypes.shape({
        nav_path: PropTypes.string
      }),
      ForgotUsername: PropTypes.shape({
        nav_path: PropTypes.string
      }),
      Register: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
      SetupUser: PropTypes.shape({
        nav_path: PropTypes.string
      }),
    }).isRequired,
    threatMetrixVars: PropTypes.shape({
      SessionId: PropTypes.string,
      ThreatMetrixUrl: PropTypes.string,
    }).isRequired,
    userLogin: PropTypes.func.isRequired,
    verifyRecaptcha: PropTypes.func.isRequired,
    loadingPublicVars: PropTypes.bool.isRequired,
  };

  state = {
    username: '',
    password: '',
    formErrors: {},
    loading: false,
    canContinue: false,
    showPassword: false,
  };

  /*
  Once this script tag is added, at login the same SessionId
  found in this ThreatMetrixUrl user here is sent with the
  Username and Password at login. Nothing else is needed from
  the client, the rest is managed between our API and TMX API.
  */
  createThreatMetrixTag() {
    // If logging in/out in same browser tab b/w different users, TMX will throw an error about too many calls, but Joel confirmed TMX is logging correctly
    const tmxScript = document.createElement('script');
    tmxScript.setAttribute('type', 'text/javascript');
    tmxScript.setAttribute('src', this.props.threatMetrixVars.ThreatMetrixUrl);
    tmxScript.setAttribute('id', 'tmxScript');
    document.head.appendChild(tmxScript);
  }

  threatMetrixPreCheck = (e) => { // gets a TMX session ID before submitting form
    e.preventDefault();

    if (!this.props.threatMetrixVars.SessionId) {
      this.setState({ loading: true });

      this.props.getThreatMetrixVars()
        .then(() => {
          this.createThreatMetrixTag();
          this.onFormSubmit();
        })
        .catch(() => this.onFormSubmit()); // in the event of a catch, log user in with an empty SessionId, and do not set the script
    }
    else {
      this.onFormSubmit();
    }
  }

  formValidate = () => {
    let formErrors = {};

    if (!this.state.username) {
      formErrors.username = this.props.text.Login.err_username;
    }

    if (!this.state.password) {
      formErrors.password = this.props.text.Login.err_password;
    }

    if (this.state.username && this.state.password) {
      formErrors = {};
    }

    if (this.state.password.length > 40) {
      formErrors.password = this.props.text.Login.password_length;
    }

    if (this.state.username.length > 128) {
      formErrors.username = this.props.text.Login.username_length;
    }

    const isValid = Object.keys(formErrors).length === 0;

    this.setState({
      formErrors,
      loading: isValid, // this is to stop loading on the TMX api call if there are form errors
    });

    return isValid;
  }

  onFormSubmit = () => {
    this.props.allNotificationsHide();

    if (this.formValidate()) {
      document.getElementById('loginUsername').blur();
      document.getElementById('loginPassword').blur();
      const deviceGUID = getRegisteredDevice();

      const params = {
        username: this.state.username,
        password: this.state.password,
        SessionId: this.props.threatMetrixVars.SessionId, // GUID for ThreatMetrix
        devicetoken: deviceGUID
      };

      this.setState({ loading: true });

      this.props.userLogin(params)
        .then(() => {
          try {
            document.cookie = 'closeAcct=true';
            window.QSI.API.run(); // stimulate Targeting.php
            window.QSI.API.unload();
            window.QSI.API.load();
            window.QSI.API.run(); // stimulate Targeting.php
          }
          catch (error) {
            // return null;
          }
        })
        .catch((response) => {
          let password = '';
          let username = '';

          if (response.payload.data.length > 0) {
            response.payload.data.forEach(element => {
              if (element.Field === 'password') {
                password = element.Message;
              }
              if (element.Field === 'username') {
                username = element.Message;
              }
            });
          }
            
          this.setState({ loading: false, formErrors: { password, username } });
        });
    }
  }

  onInputChange = (inputName, value) => {
    const clonedErrors = cloneDeep(this.state.formErrors);

    if (value.length > 0 && this.state.formErrors[inputName]) {
      clonedErrors[inputName] = '';
    }

    this.setState({
      [inputName]: value,
      formErrors: clonedErrors,
    });
  }

  recaptchaVerify = () => {
    this.setState({ loading: true });
    GETRecaptchaToken('login')
      .then(token => {
        if (token !== this.props.recaptchaToken) {
          return this.props.verifyRecaptcha(token) // need to return so it is caught in parent's finally
            .then((response) => {
              if (!this.props.recaptchaVerified) {
                const warningMessage = response.payload.data['error-codes'][0];
                this.setState({ canContinue: true });
                this.props.notificationShow(warningMessage, 'warning');
              }
            });
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.recaptchaVerify();
  }

  render() {
    const { formErrors, loading, canContinue, showPassword } = this.state;
    const {
      loadingPublicVars,
      webMessages,
      text: {
        Login: {
          nav_password, nav_username, lbl_password, btn_create_account, btn_fa_login_link,
          btn_login, nav_title, lbl_username, msg_recaptcha_language, btn_toggle_password,
        },
      },
    } = this.props;
    const forgotUsername = <StyledLink to={loading ? '' : this.props.text.ForgotUsername.nav_path} key='forgotUsernameLink'>{nav_username}</StyledLink>;
    const forgotPassword = <StyledLink to={loading ? '' : this.props.text.ForgotPassword.nav_path} key='forgotPasswordLink'>{nav_password}</StyledLink>;

    return (
      <div>
        <Banner show={Boolean(webMessages[BANNER_LOCATIONS.LOGIN])} body={webMessages[BANNER_LOCATIONS.LOGIN]} />
        <FormWrapper
          onSubmit={this.threatMetrixPreCheck}
          title={nav_title}
        >
          <LoadingOverlay show={loading || loadingPublicVars} width='100%'>
            <form
              className='formContainer'
              onSubmit={this.threatMetrixPreCheck}
            >
              <TextField
                autoComplete='off'
                disabled={loading}
                error={Boolean(formErrors.username)}
                fullWidth
                helperText={formErrors.username}
                id='loginUsername'
                label={lbl_username}
                onChange={(e) => this.onInputChange('username', e.target.value)}
                value={this.state.username}
                variant='filled'
              />
              <TextField
                autoComplete='off'
                disabled={loading}
                error={Boolean(formErrors.password)}
                helperText={formErrors.password}
                fullWidth
                id='loginPassword'
                label={lbl_password}
                onChange={(e) => this.onInputChange('password', e.target.value)}
                type={showPassword ? 'text' : 'password'}
                value={this.state.password}
                variant='filled'
                InputProps={{ 
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconBtnTooltip
                        icon={showPassword ? <Visibility /> : <VisibilityOff />}
                        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                        title={btn_toggle_password}
                      />
                    </InputAdornment>
                  )
                }}
              />
              <div className={styles.button}>
                <Button
                  disabled={canContinue || loading}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  {btn_login}
                </Button>
              </div>

              <div className={styles.linkContainer}>
                I forgot my {forgotUsername} or {forgotPassword}
              </div>

              <div className={styles.linkDivider}>
                <div className={styles.dividerLeft}><Divider /></div>
                <div className={styles.dividerText}>or</div>
                <div className={styles.dividerRight}><Divider /></div>
              </div>
              
              <div className={styles.createAccountButton}>
                <Button
                  fullWidth
                  variant='text'
                  color='primary'
                  onClick={() => this.props.history.push(this.props.text.Register.nav_path)}
                >
                  {btn_create_account}
                </Button>
              </div>
              <div className={styles.financialAdvisorLoginButton}>
                <Button 
                  color='accent2'
                  fullWidth
                  href='https://fa.my529.org/login'
                  rel='noopener noreferrer'
                  target='_blank'
                  variant='text'
                >
                  {btn_fa_login_link}
                </Button>
              </div>
            </form>
          </LoadingOverlay>
        </FormWrapper>

        <div className={styles.recaptcha}> {/* This reCAPTCHA text is required since we are hiding the badge on all pages */}
          <p>{parse(msg_recaptcha_language)}</p>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(select, {
  allNotificationsHide,
  getThreatMetrixVars,
  notificationShow,
  userLogin,
  verifyRecaptcha,
})(LanguageHOC(Login)));