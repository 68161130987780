/*
*
* MyInfo Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import SkeletonLoader from 'src/utils/components/SkeletonLoader';

import {
  agentGet,
  getStatementDeliveryMethods,
} from './actions';

import { getPreferredMethod } from 'components/Features/protected/Multifactor/actions';

import AddressCard from './AddressCard';
import EmailCard from './EmailCard';
import PasswordCard from './PasswordCard';
import PhoneNumbersCard from './PhoneNumbersCard';
import UsernameCard from './UsernameCard';
import DeliveryMethodsComp from './DeliveryMethodsComp';
import MultifactorCard from './MultifactorCard';

import MyInfoToggles from './MyInfoToggles';

import PersistentDrawers, { Row, LeftMenuItems, LeftMenuItem, RightDrawer } from './PersistentDrawers';

import {
  InfoIcon,
  My529Logo,
} from '@frontend/common';


import styles from './styles.module.scss';

const select = (state) => ({
  agent: state.myInfo.agent,
  isIpOnly_AccessRole: state.session.isIpOnly_AccessRole,
  DeliveryMethods: state.myInfo.DeliveryMethods,
  UserName: state.session.claims.UserName,
  EmailAddress: state.session.claims.EmailAddress,
  isAgent_AccessRole: state.session.isAgent_AccessRole,
  preferredInfo: state.multifactor.preferredInfo,
  claims: state.session.claims,
  is2FABypassed: state.session.is2FABypassed,
});

const DELIVERY_METHODS = {
  Online: 'Online',
  Mail: 'Mail',
  Custom: 'Custom',
  Blocked: 'Blocked',
  Select: 'Please Select',
};

const drawerWidth = '390px';
const marginTop = '60px';

export class MyInfo extends React.Component {

  static propTypes = {
    UserName: PropTypes.string.isRequired,
    DeliveryMethods: PropTypes.array.isRequired,
    getStatementDeliveryMethods: PropTypes.func.isRequired,
    agentGet: PropTypes.func.isRequired,
    getPreferredMethod: PropTypes.func.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    is2FABypassed: PropTypes.bool.isRequired,
    claims: PropTypes.object.isRequired,
    agent: PropTypes.shape({
      AgentId: PropTypes.number,
      FirstName: PropTypes.string,
      LastName: PropTypes.string,
      UtahResident: PropTypes.bool,
      PhoneNumbers: PropTypes.shape({
        Cell: PropTypes.object,
        Fax: PropTypes.object,
        Home: PropTypes.object,
        Other: PropTypes.object,
        Work: PropTypes.object,
      }),
      Addresses: PropTypes.shape({
        Physical: PropTypes.object,
        Mailing: PropTypes.object,
        Other: PropTypes.object,
      }),
      OfficialCommunications: PropTypes.string,
      TaxForms: PropTypes.string,
      Newsletter: PropTypes.string,
      Promotional: PropTypes.string,
      BirthdayNotifications: PropTypes.string,
      MiddleName: PropTypes.string,
    }).isRequired,
    text: PropTypes.shape({
      MyInfo: PropTypes.shape({
        msg_unsaved_changes: PropTypes.string,
        nav_path: PropTypes.string,
      }),
      Login: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
      AccountOwnerCard: PropTypes.shape({
        head_title: PropTypes.string,
        text_name_change_form: PropTypes.string,
      }),
      AddressCard: PropTypes.shape({
        text_intl_address: PropTypes.string,
        head_title: PropTypes.string,
      }),
      EmailCard: PropTypes.shape({
        head_title: PropTypes.string,
        text_ip_email_visible_to_ao: PropTypes.string,
      }),
      UsernameCard: PropTypes.shape({
        head_title: PropTypes.string,
      }),
      PasswordCard: PropTypes.shape({
        head_title: PropTypes.string,
      }),
      PhoneNumbersCard: PropTypes.shape({
        head_title: PropTypes.string,
        text_intl_phone: PropTypes.string,
      }),
      Preferences: PropTypes.shape({
        head_title_statement_delivery: PropTypes.string,
        head_notification_preferences: PropTypes.string,
      }),
      Multifactor: PropTypes.shape({
        nav_title: PropTypes.string,
        lbl_registered: PropTypes.string,
      }),
    }).isRequired,
    EmailAddress: PropTypes.string.isRequired,
    isAgent_AccessRole: PropTypes.bool.isRequired,
    isIpOnly_AccessRole: PropTypes.bool.isRequired,
  };

  state = {
    isLoading: false,
    displayPrompt: false,
    residentModalOpen: false,
    is2FARegisteredAO: false,
  };

  residentCheck = () => {
    if (
      (this.props.agent.UtahResident && this.props.agent.Addresses.Mailing.State !== 'UT') ||
      (!this.props.agent.UtahResident && this.props.agent.Addresses.Mailing.State === 'UT')
    ) {
      this.setState({ residentModalOpen: true });
    }
  }

  onResidentModalClose = () => this.setState({ residentModalOpen: false });

  displayUnsavedChangesPrompt = (name, formNotFinished) => {
    this.setState({ displayPrompt: { [name]: formNotFinished } });
  }

  statementDeliveryMethodToString = (DeliveryMethods) => {
    if (DeliveryMethods) {
      let statementsDeliveryMethod;
      const hasBlocked = DeliveryMethods.some(method => method.DeliveryMethod === DELIVERY_METHODS.Blocked);
      if (!hasBlocked) {
        const isAllOnline = DeliveryMethods.every(method => method.DeliveryMethod === DELIVERY_METHODS.Online);
        const isAllMail = DeliveryMethods.every(method => method.DeliveryMethod === DELIVERY_METHODS.Mail);
        if (isAllOnline) {
          statementsDeliveryMethod = DELIVERY_METHODS.Online;
        }
        else if (isAllMail) {
          statementsDeliveryMethod = DELIVERY_METHODS.Mail;
        }
        else {
          statementsDeliveryMethod = DELIVERY_METHODS.Custom;
        }
      }
      else {
        statementsDeliveryMethod = DELIVERY_METHODS.Select;
      }
      return statementsDeliveryMethod;
    }
    else {
      return 'Not found';
    } 
  }

  addressToString = (address, type) => {
    if (address.AddressId) {
      const isReadOnlyAddress = address.Country !== 'US' && address.Country !== '';
      return (
        <div className={styles.addressContainer}>
          <div className={styles.addressType}>{type}</div>
          <div className={styles.addressVerLine}>|</div>
          {address.StreetAddress1 && <div className={styles.addressStreet1}>{address.StreetAddress1}<span className={styles.addressComma}>,</span></div>}
          {address.StreetAddress2 && <div className={styles.addressStreet2}>{address.StreetAddress2}<span className={styles.addressComma}>,</span></div>}
          {address.StreetAddress3 && <div className={styles.addressStreet3}>{address.StreetAddress3}<span className={styles.addressComma}>,</span></div>}
          {address.City && <div className={styles.addressCity}>{address.City}<span className={styles.addressComma}>, </span></div>}
          {(address.PostalCode || address.State) && <div className={styles.addressStatePostal}>{address.State && address.State} {address.PostalCode && address.PostalCode}{isReadOnlyAddress && <span className={styles.addressComma}>,</span>}</div>}
          {isReadOnlyAddress && <div className={styles.addressCountry}> {address.CountryName}</div>}
        </div>
      );
    }
    else
      return null;
  }

  componentDidMount() {
    const {
      agent: { AgentId },
      isAgent_AccessRole,
      agentGet,
    } = this.props;

    const promises = [];
    if (this.props.DeliveryMethods.length === 0)
      promises.push(this.props.getStatementDeliveryMethods());

    if (!AgentId && isAgent_AccessRole)
      promises.push(agentGet());

    if (isAgent_AccessRole) {
      promises.push(this.props.getPreferredMethod());
    }

    this.setState({ isLoading: true });
    Promise.all(promises)
      .then(() => {
        isAgent_AccessRole && this.props.preferredInfo.IsRegistered
          ? this.setState({ isLoading: false, is2FARegisteredAO: true })
          : this.setState({ isLoading: false });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { displayPrompt, isLoading, residentModalOpen, is2FARegisteredAO } = this.state;

    const {
      text: { Multifactor },
      agent: {
        AgentId,
        Addresses,
        FirstName,
        LastName,
        MiddleName,
        PhoneNumbers,
      },
      isAgent_AccessRole,
      isIpOnly_AccessRole,
      EmailAddress,
      UserName,
      DeliveryMethods,
      preferredInfo,
      is2FABypassed,
      text,
    } = this.props;
    const allFormsNotFinished = Boolean(Object.keys(displayPrompt).find(key => displayPrompt[key])) && !isLoading;
    const showAgentDetail = (detail) => <SkeletonLoader isLoading={isLoading} width={500} >{detail}</SkeletonLoader>;
    const accountOwnerName = `${FirstName} ${MiddleName} ${LastName}`;

    return (
      <div>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={`${styles.aoName} ${styles.col}`}>
              <div className={styles.titleWithInfoIcon}>Account Owner <InfoIcon message={text.AccountOwnerCard.text_name_change_form} /></div>
              <h2>{accountOwnerName || <SkeletonLoader textVariant='h2' />}</h2>
            </div>
            <div className={`${styles.logo} ${styles.col}`}><My529Logo /></div>
          </div>

          <PersistentDrawers drawerWidth={drawerWidth} marginTop={marginTop} disabled={isLoading}>
            <Row>
              <LeftMenuItems>
                <LeftMenuItem>
                  <div className={styles.title}>
                    {text.UsernameCard.head_title}
                  </div>
                </LeftMenuItem>
                <LeftMenuItem>
                  <div className={styles.contentLighter}>
                    {showAgentDetail(UserName)}
                  </div>
                </LeftMenuItem>
              </LeftMenuItems>
              <RightDrawer title={text.UsernameCard.head_title}>
                <UsernameCard
                  currentUsername={UserName}
                  displayUnsavedChangesPrompt={this.displayUnsavedChangesPrompt}
                  key={`${AgentId}_Username`}
                  hasTitle={false}
                />
              </RightDrawer>
            </Row>
            <Row>
              <LeftMenuItems>
                <LeftMenuItem>
                  <div className={styles.title}>
                    {text.PasswordCard.head_title}
                  </div>
                </LeftMenuItem>
                <LeftMenuItem>
                  <div className={styles.contentLighter}>
                    {showAgentDetail('.........')}
                  </div>
                </LeftMenuItem>
              </LeftMenuItems>
              <RightDrawer title={text.PasswordCard.head_title}>
                <PasswordCard
                  displayUnsavedChangesPrompt={this.displayUnsavedChangesPrompt}
                  key={`${AgentId}_Password`}
                  hasTitle={false}
                />
              </RightDrawer>
            </Row>
            {is2FARegisteredAO && !is2FABypassed && (
              <Row>
                <LeftMenuItems>
                  <LeftMenuItem>
                    <div className={styles.title}>
                      {Multifactor.nav_title}
                    </div>
                  </LeftMenuItem>
                  <LeftMenuItem>
                    <div className={styles.contentLighter}>
                      {showAgentDetail(Multifactor.lbl_registered)}
                    </div>
                  </LeftMenuItem>
                </LeftMenuItems>
                <RightDrawer title={Multifactor.nav_title}><MultifactorCard preferredInfo={preferredInfo} /></RightDrawer>
              </Row>
            )}
            <Row>
              <LeftMenuItems>
                <LeftMenuItem>
                  <div className={styles.title}>
                    {text.EmailCard.head_title}
                  </div>
                </LeftMenuItem>
                <LeftMenuItem>
                  <div className={styles.contentLighter}>
                    {showAgentDetail(EmailAddress)}
                  </div>
                </LeftMenuItem>
              </LeftMenuItems>
              <RightDrawer
                title={(
                  <div className={styles.titleWithInfoIcon}>
                    {text.EmailCard.head_title}
                    {isIpOnly_AccessRole && <InfoIcon message={text.EmailCard.text_ip_email_visible_to_ao} />}
                  </div>
                )}
              >
                <EmailCard
                  displayUnsavedChangesPrompt={this.displayUnsavedChangesPrompt}
                  emailAddress={EmailAddress}
                  key={`${AgentId}_Email`}
                  hasTitle={false}
                />
              </RightDrawer>
            </Row>
            {isAgent_AccessRole && (
              <Row>
                <LeftMenuItems>
                  <LeftMenuItem>
                    <div className={styles.title}>
                      {text.AddressCard.head_title}
                    </div>
                  </LeftMenuItem>
                  <LeftMenuItem>
                    <div className={styles.contentLighter}>
                      {showAgentDetail(this.addressToString(Addresses.Mailing, 'Mailing'))} {/* only show 1 Skeleton loader line here */}
                      {!isLoading && this.addressToString(Addresses.Physical, 'Physical')}
                      {!isLoading && this.addressToString(Addresses.Other, 'Other')}
                    </div>
                  </LeftMenuItem>
                </LeftMenuItems>
                <RightDrawer
                  title={(
                    <div className={styles.titleWithInfoIcon}>
                      {text.AddressCard.head_title}<InfoIcon message={text.AddressCard.text_intl_address} />
                    </div>
                  )}
                >
                  <AddressCard
                    addresses={Addresses}
                    displayUnsavedChangesPrompt={this.displayUnsavedChangesPrompt}
                    key={`${AgentId}_Address`}
                    residentCheck={this.residentCheck}
                    hasTitle={false}
                  />
                </RightDrawer>
              </Row>
            )}
            {isAgent_AccessRole && (
              <Row>
                <LeftMenuItems>
                  <LeftMenuItem>
                    <div className={styles.title}>
                      {text.PhoneNumbersCard.head_title}
                    </div>
                  </LeftMenuItem>
                  <LeftMenuItem>
                    <div className={styles.contentLighter}>

                      {showAgentDetail(Object.values(PhoneNumbers).map(phone => phone.Number && (
                        <div className={styles.phoneContainer} key={phone.Type}>
                          <div className={styles.phoneType}>{phone.Type}</div>
                          <div className={styles.phoneVerLine}> | </div>
                          <div className={styles.phoneNum}>{phone.Number}</div>
                        </div>
                      )))}
                    </div>
                  </LeftMenuItem>
                </LeftMenuItems>
                <RightDrawer
                  title={(
                    <div className={styles.titleWithInfoIcon}>
                      {text.PhoneNumbersCard.head_title}<InfoIcon message={text.PhoneNumbersCard.text_intl_phone} />
                    </div>
                  )}
                >
                  <PhoneNumbersCard
                    displayUnsavedChangesPrompt={this.displayUnsavedChangesPrompt}
                    key={`${AgentId}_PhoneNumbers`}
                    phoneNumbers={PhoneNumbers}
                    hasTitle={false}
                  />
                </RightDrawer>
              </Row>
            )}
            {isAgent_AccessRole && (
              <Row>
                <LeftMenuItems>
                  <LeftMenuItem>
                    <div>{text.Preferences.head_notification_preferences}</div>
                    <div className={styles.title}><p />
                      {text.Preferences.head_title_statement_delivery}
                    </div>
                  </LeftMenuItem>
                  <LeftMenuItem>
                    <div style={{ marginTop: '34px' }}>
                      {showAgentDetail(this.statementDeliveryMethodToString(DeliveryMethods))}
                    </div>
                  </LeftMenuItem>
                </LeftMenuItems>
                <RightDrawer title={text.Preferences.head_title_statement_delivery}>
                  <DeliveryMethodsComp
                    key={DeliveryMethods.length}
                    displayUnsavedChangesPrompt={this.displayUnsavedChangesPrompt}
                  />
                </RightDrawer>
              </Row>
            )}
          </PersistentDrawers>

          {isAgent_AccessRole && (
            <MyInfoToggles
              isLoading={isLoading}
              residentCheck={this.residentCheck}
              residentModalOpen={residentModalOpen}
              onModalClose={this.onResidentModalClose}
            />
          )}

          <Prompt
            message={location => location.pathname !== text.Login.nav_path && location.pathname !== text.MyInfo.nav_path && text.MyInfo.msg_unsaved_changes}
            when={allFormsNotFinished}
          />

        </div>
      </div>
    );
  }
}

export default connect(select, {
  agentGet,
  getStatementDeliveryMethods,
  getPreferredMethod,
})(LanguageHOC(MyInfo));