import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep, isEmpty } from 'lodash';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';

import {
  LoadingOverlay,
  notificationShow,
  InfoIcon,
} from '@frontend/common';

import {
  twoFactorRegistration,
  twoFactorRegistrationUpdate,
  getTwoFactorToken
} from '../../actions';

import RegisterPhone from 'components/Features/protected/Multifactor/Registration/RegisterPhone';
import RegisterAuthy from 'components/Features/protected/Multifactor/Registration/RegisterAuthy';
import RegisterAuthenticator from 'components/Features/protected/Multifactor/Registration/RegisterAuthenticator';

import { userLogout, resetClaims, refreshClaims } from 'components/AppRoot/Navigation/actions';
import { TWO_FACTOR_STATUS, TWO_FACTOR_TYPES } from 'components/AppRoot/Navigation/constants';
import { regMethodTypes } from '../../constants.js';

import styles from './styles.module.scss';

const select = (state) => ({
  userDetails: state.session.userDetails,
  claims: state.session.claims,
  is2FARegistered: state.session.is2FARegistered,
});

export class Register extends Component {

  static propTypes = {
    twoFactorRegistration: PropTypes.func.isRequired,
    twoFactorRegistrationUpdate: PropTypes.func.isRequired,
    getTwoFactorToken: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    resetClaims: PropTypes.func.isRequired,
    refreshClaims: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    claims: PropTypes.object.isRequired,
    closeWindow: PropTypes.func,
    registerType: PropTypes.string,
    is2FARegistered: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        btn_cancel: PropTypes.string,
        btn_get_started: PropTypes.string,
        btn_learn_more: PropTypes.string,
        btn_register: PropTypes.string,
        err_options: PropTypes.string,
        err_required: PropTypes.string,
        register_title: PropTypes.string,
        register_phonenumber_label: PropTypes.string,
        lbl_authy: PropTypes.string,
        lbl_call: PropTypes.string,
        lbl_other: PropTypes.string,
        lbl_step1: PropTypes.string,
        lbl_step2: PropTypes.string,
        lbl_text: PropTypes.string,
        msg_authy_download: PropTypes.string,
        msg_authenticator_download: PropTypes.string,
        msg_2FA: PropTypes.string,
        msg_2FA_explanation: PropTypes.string,
        msg_2FA_phone: PropTypes.string,
        msg_2FA_reg_method: PropTypes.string,
        msg_powered_authy: PropTypes.string,
        nav_title: PropTypes.string,
        phone_path: PropTypes.string,
        authenticator_path: PropTypes.string,
        authy_path: PropTypes.string,
        other_path: PropTypes.string,
        text_path: PropTypes.string,
        learn_more_url: PropTypes.string,
      }),
      Login: PropTypes.shape({
        msg_you_have_logged_out: PropTypes.string,
      }),
      Home: PropTypes.shape({
        nav_path: PropTypes.string
      }),
    }).isRequired,
  };

  state = {
    isLoading: false,
    learnMoreOpen: false,
    isDomestic: true,
    phone: '',
    regMethod: '',
    countryCode: '',
    formErrors: {
      phone: '',
      regMethod: ''
    },
    isRegisterPhone: false,
    isRegisterAuthy: false,
    isRegisterAuthenticator: false
  };

  handleOptions = e => {
    this.setState({ regMethod: e.target.value });
  };

  handlePhone = (val, country) => {
    if (val.includes('+1')) {
      this.setState({
        phone: val.replace(/\D/g, ''), // strips out non-numbers
        isDomestic: true, countryCode: `+${country.dialCode}`
      });
    }
    else {
      this.setState({
        phone: val.replace(/\D/g, ''), // strips out non-numbers
        isDomestic: false, countryCode: `+${country.dialCode}`
      });
    }
  }

  registerPhone() {
    const { text: { Multifactor }, claims } = this.props;
    const { phone, regMethod } = cloneDeep(this.state);
    const formErrors = {};

    if (phone === '' || phone === '+1') {
      formErrors.phone = Multifactor.err_required;
    }

    if (regMethod === '') {
      formErrors.regMethod = Multifactor.err_options;
    }

    if (isEmpty(formErrors)) {
      const { phone, regMethod } = this.state;
      const { registerType } = this.props;
      let countryCode = '';
      let phoneNumber = '';

      if (phone[0] === '1') { // US and US territories
        countryCode = '+1'; // MUI phone component makes dialCode 4 digits long for US territories, so we override that here
        phoneNumber = phone.substr(1).trim(); // if country code starts with 1, remove it and keep the rest of the number
      }
      else {
        countryCode = this.state.countryCode;
        phoneNumber = phone.replace(countryCode.substr(1), ''); // removes the country code without the +
      }

      const regParams = {
        countryCode,
        phoneNumber,
        emailAddress: this.props.userDetails.email,
        verificationMethod: regMethod
      };

      const apiCall = claims.TwoFactorRegistration === TWO_FACTOR_STATUS.COMPLETED
        ? 'twoFactorRegistrationUpdate'
        : 'twoFactorRegistration';

      this.setState({ isLoading: true });
      this.props[apiCall](regParams)
        .then(() => {
          switch (regMethod) {
            case regMethodTypes.SMS:
            case regMethodTypes.CALL: {
              this.props.getTwoFactorToken(regMethod)
                .then(() => {
                  registerType === TWO_FACTOR_TYPES.UPDATE
                    ? this.setState({ isRegisterPhone: true })
                    : this.props.history.push(Multifactor.phone_path);
                });
              break;
            }
            case regMethodTypes.INAPP: {
              registerType === TWO_FACTOR_TYPES.UPDATE
                ? this.setState({ isRegisterAuthy: true })
                : this.props.history.push(Multifactor.authy_path);
              break;
            }
            case regMethodTypes.INAPPTOKEN: {
              registerType === TWO_FACTOR_TYPES.UPDATE
                ? this.setState({ isRegisterAuthenticator: true })
                : this.props.history.push(Multifactor.authenticator_path);
              break;
            }
            default: break;
          }
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
    else {
      this.setState({ formErrors });
    }
  }

  logout = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .then(() => {
        this.props.notificationShow(this.props.text.Login.msg_you_have_logged_out, 'success');
      });
  }

  componentDidUpdate() {
    window.onpopstate = () => {
      if (this.props.registerType === TWO_FACTOR_TYPES.UPDATE) {
        this.props.refreshClaims();
      }
    };
  }

  render() {
    const { text: { Multifactor }, registerType, closeWindow, is2FARegistered } = this.props;
    const {
      isLoading, isDomestic, regMethod, formErrors,
      isRegisterPhone, isRegisterAuthy, isRegisterAuthenticator } = this.state;

    let renderComponent = '';
    if (isRegisterPhone) {
      renderComponent = <RegisterPhone registerType={registerType} closeWindow={closeWindow} />;
    }
    else if (isRegisterAuthy) {
      renderComponent = <RegisterAuthy />;
    }
    else if (isRegisterAuthenticator) {
      renderComponent = <RegisterAuthenticator />;
    }
    else {
      renderComponent = (
        <LoadingOverlay show={isLoading}>
          <h3>{Multifactor.register_title}</h3>
          <div className={styles.container}>
            <div className={styles.label}>{Multifactor.lbl_step1}</div>
            <div className={styles.text}>{Multifactor.msg_2FA_phone}</div>
          </div>
          <MuiPhoneNumber
            defaultCountry={'us'}
            onChange={this.handlePhone}
            disableAreaCodes={true}
            error={Boolean(formErrors.phone)}
            helperText={formErrors.phone}
            label={Multifactor.register_phonenumber_label}
            variant='filled'
            autoFocus={true}
          />
          <div className={styles.container}>
            <div className={styles.label}>{Multifactor.lbl_step2}</div>
            <div className={styles.text}>{Multifactor.msg_2FA_reg_method}</div>
          </div>
          <div className={styles.options}>
            <RadioGroup aria-label='registration method' name='method' value={regMethod} onChange={this.handleOptions}>
              {isDomestic &&
                <React.Fragment>
                  <FormControlLabel value={regMethodTypes.SMS} control={<Radio />} label={Multifactor.lbl_text} />
                  <FormControlLabel value={regMethodTypes.CALL} control={<Radio />} label={Multifactor.lbl_call} />
                </React.Fragment>
              }
              <div className={styles.auth_container} style={{ textAlign: 'left' }}>
                <div className={styles.authy}>
                  <FormControlLabel value={regMethodTypes.INAPP} control={<Radio />} label={Multifactor.lbl_authy} style={{ marginRight: '5px' }} />
                  <InfoIcon message={Multifactor.msg_authy_download} />
                </div>
                <div className={styles.authenticator}>
                  <FormControlLabel value={regMethodTypes.INAPPTOKEN} control={<Radio />} label={Multifactor.lbl_other} style={{ marginRight: '5px' }} />
                  <InfoIcon message={Multifactor.msg_authenticator_download} />
                </div>
              </div>
            </RadioGroup>
            <FormHelperText style={{ color: 'red' }}>{formErrors.regMethod}</FormHelperText>
          </div>
          <Button
            variant='contained'
            style={{ width: '60%' }}
            onClick={() => this.registerPhone()}
          >
            {Multifactor.btn_register}
          </Button>
          <Button
            color='primary'
            variant='text'
            style={{ width: '60%', marginTop: '10px' }}
            onClick={is2FARegistered ? closeWindow : this.logout}
          >
            {Multifactor.btn_cancel}
          </Button>
          <Button
            color='primary'
            variant='outlined'
            style={{ width: '60%', marginTop: '10px' }}
            onClick={() => window.open(Multifactor.learn_more_url, '_blank', 'noopener noreferrer')}
          >
            {Multifactor.btn_learn_more}
          </Button><br />
          <img src={require('../../PwddByAuthy.jpg')} alt={Multifactor.msg_powered_authy} />
        </LoadingOverlay>
      );
    }

    return (
      <div style={{ textAlign: 'center' }}>
        {renderComponent}
      </div>
    );
  }
}

export default withRouter(connect(select, {
  twoFactorRegistration,
  twoFactorRegistrationUpdate,
  getTwoFactorToken,
  resetClaims,
  refreshClaims,
  userLogout,
  notificationShow
})(LanguageHOC(Register)));