import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import { Button } from '@mui/material';

import { LoadingOverlay } from '@frontend/common';

import { resetClaims } from 'components/AppRoot/Navigation/actions';

const select = state => ({
  prev2faRoute: state.session.prev2faRoute,
});

export class Confirmation extends Component {

  static propTypes = {
    prev2faRoute: PropTypes.string.isRequired,
    resetClaims: PropTypes.func.isRequired,
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        btn_done: PropTypes.string,
        btn_learn_more: PropTypes.string,
        msg_congratulations: PropTypes.string,
        msg_later_prompt: PropTypes.string,
        msg_powered_authy: PropTypes.string,
        msg_successful: PropTypes.string,
        learn_more_url: PropTypes.string,
      }),
      Home: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isLoading: false,
  };

  resetClaims() {
    this.setState({ isLoading: true });
    this.props.resetClaims()
      .then(() => {
        this.props.history.push(this.props.prev2faRoute);
      });
  }

  render() {
    const { text: { Multifactor } } = this.props;
    return (
      <div style={{ textAlign: 'center', padding: '15px' }}>
        <LoadingOverlay show={this.state.isLoading}>
          <h2>{Multifactor.msg_congratulations}</h2>
          <p>{Multifactor.msg_successful}</p>
          <p>{Multifactor.msg_later_prompt}</p>
          <Button
            variant='contained'
            style={{ width: '60%' }}
            onClick={() => this.resetClaims()}
          >
            {Multifactor.btn_done}
          </Button>
          <Button
            color='primary'
            variant='outlined'
            style={{ width: '60%', marginTop: '10px' }}
            onClick={() => window.open(Multifactor.learn_more_url, '_blank', 'noopener noreferrer')}
          >
            {Multifactor.btn_learn_more}
          </Button><br />
          <img src={ require('../../PwddByAuthy.jpg') } alt={Multifactor.msg_powered_authy} />
        </LoadingOverlay>
      </div>
    );
  }
}

export default withRouter(connect(select, { resetClaims })(LanguageHOC(Confirmation)));