import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { getRegisteredDevice } from 'utils/helpers/registered_device_handler';

import {
  Button,
  Dialog
} from '@mui/material';

import { My529Logo } from '@frontend/common';

import styles from './styles.module.scss';

export function AuthyDown({ 
  text, isOpen, continueToApp, continueInPlace,
  rsaVerification, logout, history
}) {
  let continueMsg, display;
  if (history.location.pathname === text.Multifactor.register_path) {
    continueMsg = text.Multifactor.msg_may_continue;    
    display = (
      <React.Fragment>
        <Button
          variant='contained'
          style={{ width: '60%', marginTop: '20px' }}
          onClick={continueToApp}
        >
          {text.Multifactor.btn_continue_aa}
        </Button>
        <Button
          color='primary'
          variant='text'
          style={{ width: '60%', margin: '20px' }}
          onClick={logout}
        >
          {text.Navigation.menu_log_out}
        </Button>
        <Button
          color='primary'
          variant='outlined'
          style={{ width: '60%', marginTop: '20px' }}
          onClick={() => window.open(text.Multifactor.learn_more_url, '_blank', 'noopener noreferrer')}
        >
          {text.Multifactor.btn_learn_more}
        </Button>
      </React.Fragment>
    );
  }
  else {
    if (history.location.pathname === text.Home.nav_path) { // login or protected transactions
      const device = getRegisteredDevice();
      if (device === '') { // challenged at login
        continueMsg = text.Multifactor.msg_may_continue_nonreg;
        display = (
          <React.Fragment>
            <Button
              variant='contained'
              style={{ width: '60%', marginTop: '20px' }}
              onClick={continueToApp}
            >
              {text.Multifactor.btn_continue_aa}
            </Button>
            <Button
              color='primary'
              variant='text'
              style={{ width: '60%', margin: '20px' }}
              onClick={logout}
            >
              {text.Multifactor.btn_try_again}
            </Button>
            <Button
              color='primary'
              variant='outlined'
              style={{ width: '60%', marginTop: '20px' }}
              onClick={() => window.open(text.Multifactor.learn_more_url, '_blank', 'noopener noreferrer')}
            >
              {text.Multifactor.btn_learn_more}
            </Button>
          </React.Fragment>
        );
      }
      else { // challenged at transactions
        continueMsg = text.Multifactor.msg_may_verify;
        display = (
          <React.Fragment>
            <Button
              variant='contained'
              style={{ width: '60%', marginTop: '20px' }}
              onClick={rsaVerification}
            >
              {text.Multifactor.btn_verify_identity}
            </Button>
            <Button
              color='primary'
              variant='text'
              style={{ width: '60%', margin: '20px' }}
              onClick={continueInPlace}
            >
              {text.Multifactor.btn_try_again}
            </Button>
          </React.Fragment>
        );
      }
    }
    else { // challenged on non transaction protected actions
      continueMsg = text.Multifactor.msg_may_verify;
      display = (
        <React.Fragment>
          <Button
            variant='contained'
            style={{ width: '60%', marginTop: '20px' }}
            onClick={rsaVerification}
          >
            {text.Multifactor.btn_verify_identity}
          </Button>
          <Button
            color='primary'
            variant='text'
            style={{ width: '60%', margin: '20px' }}
            onClick={continueInPlace}
          >
            {text.Multifactor.btn_try_again}
          </Button>
        </React.Fragment>
      );
    }
  }
  
  return (
    <Dialog 
      open={isOpen}
    >
      <div className={styles.container}>
        <div className={styles.logo}><My529Logo /></div>
        <h3>{text.Multifactor.msg_2FA_unavailable}</h3>
        <div className={styles.msg}>{continueMsg}</div>
        {display}
        <br /><img src={ require('../PwddByAuthy.jpg') } alt={text.Multifactor.msg_powered_authy} />
      </div>
    </Dialog>
  );
}

AuthyDown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  continueToApp: PropTypes.func.isRequired,
  continueInPlace: PropTypes.func.isRequired,
  rsaVerification: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  text: PropTypes.shape({
    Multifactor: PropTypes.shape({
      btn_continue_aa: PropTypes.string,
      btn_learn_more: PropTypes.string,
      btn_try_again: PropTypes.string,
      btn_verify_identity: PropTypes.string,
      msg_2FA_unavailable: PropTypes.string,
      msg_may_continue: PropTypes.string,
      msg_may_continue_nonreg: PropTypes.string,
      msg_may_verify: PropTypes.string,
      msg_powered_authy: PropTypes.string,
      learn_more_url: PropTypes.string,
      register_path: PropTypes.string,
      rsa_path: PropTypes.string,
    }),
    Navigation: PropTypes.shape({
      menu_log_out: PropTypes.string
    }),
    Home: PropTypes.shape({
      nav_path: PropTypes.string
    }),
  }).isRequired,
};

export default withRouter(LanguageHOC(AuthyDown));