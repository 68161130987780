import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
  TextField
} from '@mui/material';

import {
  notificationShow,
  LoadingOverlay,
} from '@frontend/common';

import {
  twoFactorTokenVerification,
  getTwoFactorPush,
  getPreferredMethod,
  savePreferredMethod
} from '../../actions';

import { regMethodTypes } from '../../constants.js';

import { userLogout } from 'components/AppRoot/Navigation/actions';

import styles from './styles.module.scss';

const select = (state) => ({
  VerificationInfo: state.multifactor.VerificationInfo,
  pushInfo: state.multifactor.pushInfo,
  preferredInfo: state.multifactor.preferredInfo,
  is2FARegistered: state.session.is2FARegistered,
});

export class RegisterAuthy extends Component {

  static propTypes = {
    twoFactorTokenVerification: PropTypes.func.isRequired,
    getTwoFactorPush: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    getPreferredMethod: PropTypes.func.isRequired,
    savePreferredMethod: PropTypes.func.isRequired,
    VerificationInfo: PropTypes.object.isRequired,
    pushInfo: PropTypes.object.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    closeWindow: PropTypes.func,
    is2FARegistered: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        btn_another_method: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_learn_more: PropTypes.string,
        btn_request_onetouch: PropTypes.string,
        btn_submit_token: PropTypes.string,
        confirmation_path: PropTypes.string,
        err_token_required: PropTypes.string,
        lbl_invalid_code: PropTypes.string,
        lbl_token: PropTypes.string,
        lbl_step1: PropTypes.string,
        lbl_step2: PropTypes.string,
        msg_token_attempts_remaining: PropTypes.string,
        msg_authy_or: PropTypes.string,
        msg_download_authy: PropTypes.string,
        msg_enter_token: PropTypes.string,
        msg_enter_authy_token: PropTypes.string,
        msg_powered_authy: PropTypes.string,
        authy_onetouch_path: PropTypes.string,
        register_path: PropTypes.string,
        learn_more_url: PropTypes.string,
      }),
      Login: PropTypes.shape({
        msg_you_have_logged_out: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isLoading: false,
    hasToken: false,
    authyToken: '',
    tokenErrorCode: '',
    attemptWarning: false,
    buttonType: '',
  };

  handleInput = name => e => {
    if (this.state.authyToken) {
      this.setState({
        token: false,
        tokenErrorCode: '',
        [name]: e.target.value
      });
    }
    else {
      this.setState({ [name]: e.target.value });
    }
  }

  logout = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .then(() => {
        this.props.notificationShow(this.props.text.Login.msg_you_have_logged_out, 'success');
      });
  }

  requestOneTouch = () => {
    const { text: { Multifactor } } = this.props;
    this.setState({ isLoading: true });
    this.props.getTwoFactorPush()
      .then(() => {
        if (this.props.pushInfo.Sent) {
          this.props.history.push(Multifactor.authy_onetouch_path);
        }
        else {
          this.setState({ isLoading: false });
          notificationShow(this.props.pushInfo.message, 'error');
        }
      });
  }

  verifyToken = (e) => {
    e.preventDefault();
    const { text: { Multifactor } } = this.props;
    const { authyToken } = this.state;
    if (authyToken) {
      this.setState({ isLoading: true });
      Promise.all([
        this.props.twoFactorTokenVerification(authyToken),
        this.props.getPreferredMethod()
      ]).then(() => {
        const { VerificationInfo, preferredInfo } = this.props;
        if (this.props.VerificationInfo.Verified) {
          const params = {
            AlwaysRequired: preferredInfo.AlwaysRequired,
            PreferredMethod: regMethodTypes.INAPP
          };
          this.props.savePreferredMethod(params)
            .then(() => {
              this.props.history.push(Multifactor.confirmation_path);
            });
        }
        else {
          if (VerificationInfo.AttemptsRemaining === 0) {
            this.logout();
          }
          else if (VerificationInfo.AttemptsRemaining === 1) {
            this.setState({
              isLoading: false,
              attemptWarning: Multifactor.msg_token_attempts_remaining
            });
          }
          else {
            this.setState({
              isLoading: false,
              tokenErrorCode: Multifactor.lbl_invalid_code
            });
          }
        }
      });
    }
    else {
      this.setState({
        hasToken: true,
        tokenErrorCode: Multifactor.err_token_required
      });
    }
  }

  render() {
    const { text: { Multifactor }, closeWindow, is2FARegistered } = this.props;
    const {
      isLoading, hasToken, tokenErrorCode,
      attemptWarning, authyToken
    } = this.state;
    return (
      <div style={{ textAlign: 'center' }}>
        <LoadingOverlay show={isLoading}>
          <h2>{Multifactor.msg_enter_token}</h2>
          <div className={styles.container}>
            <div className={styles.step1}>{Multifactor.lbl_step1}</div>
            <div className={styles.step1text}>{Multifactor.msg_download_authy}</div>
            <div className={styles.step2}>{Multifactor.lbl_step2}</div>
            <div className={styles.step2text}>{Multifactor.msg_enter_authy_token}</div>
          </div>
          <div>
            <Button
              variant='contained'
              style={{ width: '60%', marginTop: '20px' }}
              onClick={() => this.requestOneTouch()}
            >
              {Multifactor.btn_request_onetouch}
            </Button>
          </div>
          <span><h1>{Multifactor.msg_authy_or}</h1></span>
          <form
            id='verify'
            onSubmit={e => this.verifyToken(e)}
          >
            <div>
              <TextField
                error={hasToken || Boolean(tokenErrorCode)}
                helperText={tokenErrorCode}
                label={Multifactor.lbl_token}
                onChange={this.handleInput('authyToken')}
                value={authyToken}
                variant='filled'
                autoFocus={true}
              />
            </div>
            <Button
              variant='contained'
              style={{ width: '60%', marginTop: '20px' }}
              type='submit'
            >
              {Multifactor.btn_submit_token}
            </Button>
          </form>
          <div className={styles.attemptWarning}>{attemptWarning}</div>
          <div>
            <Button
              color='primary'
              disabled={isLoading}
              style={{ width: '60%', marginTop: '20px' }}
              onClick={() => this.props.history.push(Multifactor.register_path)}
            >
              {Multifactor.btn_another_method}
            </Button>
          </div>
          <div>
            <Button
              color='primary'
              variant='text'
              disabled={isLoading}
              style={{ width: '60%', marginTop: '10px' }}
              onClick={is2FARegistered ? closeWindow : this.logout}
            >
              {Multifactor.btn_cancel}
            </Button>
          </div>
          <Button
            color='primary'
            variant='outlined'
            style={{ width: '60%', marginTop: '10px' }}
            onClick={() => window.open(Multifactor.learn_more_url, '_blank', 'noopener noreferrer')}
          >
            {Multifactor.btn_learn_more}
          </Button><br />
          <img src={require('../../PwddByAuthy.jpg')} alt={Multifactor.msg_powered_authy} />
        </LoadingOverlay>
      </div>
    );
  }
}

export default withRouter(connect(select, {
  twoFactorTokenVerification,
  getTwoFactorPush,
  getPreferredMethod,
  savePreferredMethod,
  userLogout,
  notificationShow
})(LanguageHOC(RegisterAuthy)));