/*
*
* PasswordCard Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { camelCase } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  PASSWORD_CHANGE,
} from '../constants';

import {
  CardButtons,
  CardTitle,
  IconBtnTooltip,
  PasswordRequirements,
  notificationShow,
} from '@frontend/common';

import {
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';

import { passwordRequirementsGet } from 'components/AppRoot/StaticResources/actions';
import { passwordChange } from '../actions';

import parentStyles from '../styles.module.scss';

const select = (state) => ({
  passwordRequirements: state.static.passwordRequirements,
});

export class PasswordCard extends React.Component {
  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    displayUnsavedChangesPrompt: PropTypes.func.isRequired,
    passwordChange: PropTypes.func.isRequired,
    passwordRequirements: PropTypes.array.isRequired,
    passwordRequirementsGet: PropTypes.func.isRequired,
    hasTitle: PropTypes.bool,
    text: PropTypes.shape({
      PasswordCard: PropTypes.shape({
        btn_change_password: PropTypes.string,
        btn_toggle_password: PropTypes.string,
        err_confirm_password: PropTypes.string,
        err_password_criteria: PropTypes.string,
        err_required: PropTypes.string,
        head_title: PropTypes.string,
        lbl_confirm_password: PropTypes.string,
        lbl_current_password: PropTypes.string,
        lbl_new_password: PropTypes.string,
        msg_password_updated: PropTypes.string,
      })
    }).isRequired,
  };

  static defaultProps = {
    hasTitle: true,
  };

  state = {
    currentPassword: '',
    inputErrors: [],
    apiErrors: [],
    isLoading: false,
    newPassword: '',
    newPasswordConfirm: '',
    passwordIsValid: false,
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  };

  errorGet(input) {
    const error = this.state.inputErrors.find((error) => error.input === input);
    return error ? error.message : '';
  }

  updateButtonIsDisabled = () => {
    return !this.state.newPassword && !this.state.newPasswordConfirm;
  }

  inputChange(input, value) {
    this.setState({
      [input]: value,
      inputErrors: this.state.inputErrors.filter(error => error.input !== input),
    });
  }

  passwordInputsValidate() {
    const { PasswordCard: { err_confirm_password, err_password_criteria, err_required } } = this.props.text;
    const errors = [];
    const { currentPassword, newPassword, newPasswordConfirm, passwordIsValid, inputErrors, apiErrors } = this.state;

    if (!currentPassword) {
      errors.push({
        input: 'currentPassword',
        message: err_required,
      });
    }

    if (!newPassword) {
      errors.push({
        input: 'newPassword',
        message: err_required,
      });
    }

    if (!newPasswordConfirm) {
      errors.push({
        input: 'newPasswordConfirm',
        message: err_required,
      });
    }

    if (newPassword && !passwordIsValid) {
      errors.push({
        input: 'newPassword',
        message: err_password_criteria,
      });
    }

    if (newPassword && newPasswordConfirm && newPassword !== newPasswordConfirm) {
      errors.push({
        input: 'newPasswordConfirm',
        message: err_confirm_password,
      });
    }

    if (apiErrors.length > 0) {
      // this is assuming that api Field properties would not change!
      apiErrors.forEach(error => {
        if (error.Field === 'OldPassword') {
          errors.push({
            input: 'currentPassword',
            message: error.Message
          });
        }
        else if (error.Field === 'newpassword1') {
          errors.push({
            input: 'newPassword',
            message: error.Message
          });
        }
        else if (error.Field === 'newpassword2') {
          errors.push({
            input: 'newPasswordConfirm',
            message: error.Message
          });
        }
        else {
          errors.push({
            input: camelCase(error.Field),
            message: error.Message
          });
        }
      });
    }

    this.setState({
      inputErrors: [...inputErrors, ...errors],
      apiErrors: []
    });

    return errors.length === 0;
  }

  passwordUpdate = (e) => {
    e.preventDefault();
    if (this.passwordInputsValidate()) {
      this.setState({ isLoading: true });
      this.props.passwordChange(this.state.currentPassword, this.state.newPassword, this.state.newPasswordConfirm)
        .then(() => {
          this.props.notificationShow(this.props.text.PasswordCard.msg_password_updated, 'success');
          this.setState({
            isLoading: false,
            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
          });
        })
        .catch(response => this.setState({
          apiErrors: response.payload.data,
          isLoading: false
        }, () => this.passwordInputsValidate()));
    }
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowCurrentPassword = () => {
    this.setState({ showCurrentPassword: !this.state.showCurrentPassword });
  }

  handleClickShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  }

  handleClickShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }

  componentDidMount() {
    if (this.props.passwordRequirements.length === 0) {
      this.setState({ isLoading: true });
      this.props.passwordRequirementsGet()
        .then(() => this.setState({ isLoading: false }))
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  componentDidUpdate() {
    if (this.state.newPassword || this.state.newPasswordConfirm) {
      this.props.displayUnsavedChangesPrompt(PASSWORD_CHANGE, true);
    }
    else {
      this.props.displayUnsavedChangesPrompt(PASSWORD_CHANGE, false);
    }
  }

  render() {
    const {
      currentPassword,
      isLoading,
      newPassword,
      newPasswordConfirm,
      showCurrentPassword,
      showNewPassword,
      showConfirmPassword,
    } = this.state;

    const {
      text: {
        PasswordCard: {
          btn_change_password,
          btn_toggle_password,
          head_title,
          lbl_confirm_password,
          lbl_current_password,
          lbl_new_password,
        }
      },
      hasTitle,
    } = this.props;

    return (
      <form onSubmit={this.passwordUpdate}>
        <div>
          {hasTitle && <CardTitle>{head_title}</CardTitle>}
          <div>
            <TextField
              autoComplete='username'
              style={{ display: 'none' }}
              type='text'
            />
            <TextField
              label={lbl_current_password}
              name='currentPassword'
              type={showCurrentPassword ? 'text' : 'password'}
              value={currentPassword}
              onChange={(e) => this.inputChange('currentPassword', e.target.value)}
              error={Boolean(this.errorGet('currentPassword'))}
              helperText={this.errorGet('currentPassword')}
              autoComplete='current-password'
              disabled={isLoading}
              fullWidth
              InputProps={{ 
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconBtnTooltip
                      icon={showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                      onClick={this.handleClickShowCurrentPassword}
                      title={btn_toggle_password}
                    />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label={lbl_new_password}
              name='newPassword'
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => this.inputChange('newPassword', e.target.value)}
              error={Boolean(this.errorGet('newPassword'))}
              helperText={this.errorGet('newPassword')}
              autoComplete='new-password'
              disabled={isLoading}
              fullWidth
              InputProps={{ 
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconBtnTooltip
                      icon={showNewPassword ? <Visibility /> : <VisibilityOff />}
                      onClick={this.handleClickShowNewPassword}
                      title={btn_toggle_password}
                    />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label={lbl_confirm_password}
              name='newPasswordConfirm'
              type={showConfirmPassword ? 'text' : 'password'}
              value={newPasswordConfirm}
              onChange={(e) => this.inputChange('newPasswordConfirm', e.target.value)}
              error={Boolean(this.errorGet('newPasswordConfirm'))}
              helperText={this.errorGet('newPasswordConfirm')}
              autoComplete='new-password'
              disabled={isLoading}
              fullWidth
              InputProps={{ 
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconBtnTooltip
                      icon={showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      onClick={this.handleClickShowConfirmPassword}
                      title={btn_toggle_password}
                    />
                  </InputAdornment>
                )
              }}
            />
            <div className={parentStyles.passwordRequirements}>
              <PasswordRequirements
                className={parentStyles.passwordRequirements}
                password={newPassword}
                passwordRequirements={this.props.passwordRequirements}
                onPasswordCheck={(passwordIsValid) => this.setState({ passwordIsValid })}
              />
            </div>
          </div>
        </div>
        <CardButtons>
          <Button
            disabled={isLoading || this.updateButtonIsDisabled()}
            key='passwordUpdateButton'
            type='submit'
            variant='contained'
          >
            {btn_change_password}
          </Button>
        </CardButtons>
      </form>
    );
  }
}


export default connect(select, {
  notificationShow,
  passwordRequirementsGet,
  passwordChange,
})(LanguageHOC(PasswordCard));