import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import { Button } from '@mui/material';

import {
  notificationShow,
  LoadingOverlay,
} from '@frontend/common';

import {
  getTwoFactorPushVerification,
  getTwoFactorPush
} from '../../actions';

import { userLogout } from 'components/AppRoot/Navigation/actions';

const select = (state) => ({
  pushVerificationInfo: state.multifactor.pushVerificationInfo,
  pushInfo: state.multifactor.pushInfo,
  is2FARegistered: state.session.is2FARegistered,
});

export class RegisterAuthyOneTouch extends Component {

  static propTypes = {
    getTwoFactorPushVerification: PropTypes.func.isRequired,
    getTwoFactorPush: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    pushVerificationInfo: PropTypes.object.isRequired,
    pushInfo: PropTypes.object.isRequired,
    is2FARegistered: PropTypes.bool.isRequired,
    closeWindow: PropTypes.func,
    userLogout: PropTypes.func.isRequired,
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        btn_learn_more: PropTypes.string,
        btn_send_newpush: PropTypes.string,
        btn_another_method: PropTypes.string,
        confirmation_path: PropTypes.string,
        msg_powered_authy: PropTypes.string,
        msg_pushed_login: PropTypes.string,
        msg_send_anotherpush: PropTypes.string,
        register_path: PropTypes.string,
        learn_more_url: PropTypes.string,
        btn_cancel: PropTypes.string,
      }),
      Login: PropTypes.shape({
        msg_you_have_logged_out: PropTypes.string,
      })
    }).isRequired,
  };

  state = {
    isLoading: false,
    isPending: true,
    hasToken: false,
    token: ''
  };

  handleInput = name => e => {
    if (this.state.token) {
      this.setState({ token: false, [name]: e.target.value });
    }
    else {
      this.setState({ [name]: e.target.value });
    }
  }

  sendNewPush() {
    this.setState({ isLoading: true });
    this.props.getTwoFactorPush()
      .then(() => {
        if (this.props.pushInfo.Sent) {
          this.setState({ isPending: true, isLoading: false });
          this.verifyPush();
        }
        else {
          this.setState({ isLoading: false });
          this.props.notificationShow(this.props.pushInfo.Message, 'error');
        }
      });
  }

  verifyPush() {
    const { text: { Multifactor } } = this.props;
    this.setState({ isPending: true });
    this.props.getTwoFactorPushVerification()
      .then(() => {
        if (this.props.pushVerificationInfo.Verified) {
          this.props.history.push(Multifactor.confirmation_path);
        }
        else if (!this.props.pushVerificationInfo.Verified && this.props.pushVerificationInfo.Message !== 'Pending') {
          this.setState({ isPending: false });
          this.props.notificationShow(Multifactor.msg_send_anotherpush, 'warning');
        }
        else {
          setTimeout(() => {
            this.verifyPush();
          }, 5000);
        }
      })
      .catch(() => {
        this.setState({ isPending: false });
      });
  }

  logout = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .then(() => {
        this.props.notificationShow(this.props.text.Login.msg_you_have_logged_out, 'success');
      });
  }

  componentDidMount() {
    this.verifyPush();
  }

  render() {
    const { text: { Multifactor }, is2FARegistered, closeWindow } = this.props;
    const { isLoading, isPending } = this.state;
    return (
      <LoadingOverlay show={isLoading} width='100%'>
        <div style={{ textAlign: 'center', width: '60%', margin: 'auto' }}>
          <h3>{Multifactor.msg_pushed_login}</h3>
          <img src={require('../../GifAuthy.gif')} alt={Multifactor.msg_powered_authy} /><br />
          <Button
            color='primary'
            variant='text'
            style={{ width: '60%', marginTop: '20px' }}
            onClick={() => this.sendNewPush()}
            disabled={isPending}
          >
            {Multifactor.btn_send_newpush}
          </Button>
          <Button
            color='primary'
            variant='text'
            style={{ width: '60%', marginTop: '20px' }}
            onClick={() => this.props.history.push(Multifactor.register_path)}
          >
            {Multifactor.btn_another_method}
          </Button>

          <Button
            color='primary'
            variant='text'
            disabled={isLoading}
            style={{ width: '60%', marginTop: '20px' }}
            onClick={is2FARegistered ? closeWindow : this.logout}
          >
            {Multifactor.btn_cancel}
          </Button>

          <Button
            color='primary'
            variant='outlined'
            style={{ width: '60%', marginTop: '10px' }}
            onClick={() => window.open(Multifactor.learn_more_url, '_blank', 'noopener noreferrer')}
          >
            {Multifactor.btn_learn_more}
          </Button><br />
          <img src={require('../../PwddByAuthy.jpg')} alt={Multifactor.msg_powered_authy} />
        </div>
      </LoadingOverlay>
    );
  }
}

export default withRouter(connect(select, {
  getTwoFactorPushVerification,
  getTwoFactorPush,
  notificationShow,
  userLogout
})(LanguageHOC(RegisterAuthyOneTouch)));