
export const DEVICE_TOKEN_CREATE = 'src/components/Features/protected/Multifactor/DEVICE_TOKEN_CREATE';
export const DEVICE_TOKEN_GET = 'src/components/Features/protected/Multifactor/DEVICE_TOKEN_GET';

export const SECURITY_QESTION_GET = 'src/components/Features/protected/Multifactor/SECURITY_QESTION_GET';
export const SECURITY_QESTIONS_ANSWER = 'src/components/Features/protected/Multifactor/SECURITY_QESTIONS_ANSWER';

export const RSA_STATUS_GET = 'src/components/Features/protected/Multifactor/RSA_STATUS_GET';
export const RSA_QESTIONS_GET = 'src/components/Features/protected/Multifactor/RSA_QESTIONS_GET';
export const RSA_ANSWERS = 'src/components/Features/protected/Multifactor/RSA_ANSWERS';
export const RSA_VERIFY_BIRTHDAY = 'src/components/Features/protected/Multifactor/RSA_VERIFY_BIRTHDAY';

export const TWO_FACTOR_REGISTRATION = 'src/components/Features/protected/Multifactor/TWO_FACTOR_REGISTRATION';
export const TWO_FACTOR_REGISTRATION_UPDATE = 'src/components/Features/protected/Multifactor/TWO_FACTOR_REGISTRATION_UPDATE';
export const TWO_FACTOR_TOKEN = 'src/components/Features/protected/Multifactor/TWO_FACTOR_TOKEN';
export const TWO_FACTOR_TOKEN_VERIFICATION = 'src/components/Features/protected/Multifactor/TWO_FACTOR_TOKEN_VERIFICATION';
export const TWO_FACTOR_QRCODES = 'src/components/Features/protected/Multifactor/TWO_FACTOR_QRCODES';
export const TWO_FACTOR_PUSH = 'src/components/Features/protected/Multifactor/TWO_FACTOR_PUSH';
export const TWO_FACTOR_PUSH_VERIFICATION = 'src/components/Features/protected/Multifactor/TWO_FACTOR_PUSH_VERIFICATION';

export const TWO_FACTOR_PREFERRED_METHOD = 'src/components/Features/protected/Multifactor/TWO_FACTOR_PREFERRED_METHOD';
export const TWO_FACTOR_SAVE_METHOD = 'src/components/Features/protected/Multifactor/TWO_FACTOR_SAVE_METHOD';

export const regMethodTypes = {
  SMS: 'SMS',
  CALL: 'CALL',
  INAPP: 'INAPP',
  INAPPTOKEN: 'INAPPTOKEN'
};